/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from 'components/MDInput'
import Select from 'react-select'
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import axios from 'axios';
// Material Dashboard 2 React example components
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import usdTicket from "../functions/usdTicket";
import { toast } from 'react-toastify'
import { useEffect, useState } from "react";

toast.configure()
function ConfirmUsedTicket({open, setOpen, ticket,getAllData}) {
  const [reason, setReason] = useState(false)
  const [errors, setErrors] = useState('')
  const [disableSubmit, setDisableSubmit] = useState(false)

  const handleClose = () => {
    setOpen(false)
    setDisableSubmit(false)
  }
  const styles = {
    height: 200,
  }
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  const onsubmitUse = async () =>{

    setDisableSubmit(true)
    await usdTicket( ticket, handleClose, getAllData)
    //  window.location.reload()

  }

  useEffect(() => {
    if (localStorage.getItem("Token")) {
      const decodedJwt = parseJwt(localStorage.getItem("Token"));
      if (decodedJwt.exp * 1000 < Date.now()) {

      localStorage.removeItem('Token')
      }
    }
    const loggedInAdmin = localStorage.getItem("Token")
    if (!loggedInAdmin) {
      window.location.replace('/') 
    }
  
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth
    maxWidth="xs">
      <DialogTitle>Ticket utilisé</DialogTitle>
        <MDBox pt={2} py={-10} pb={3} px={3}>
          <MDTypography variant='button' textTransform="none" color='black'>
          Voulez-vous marquer ce ticket comme utilisé ?
          </MDTypography>
        </MDBox>
        <DialogActions>
          <MDButton
            variant='text'
            color='error'
            size = "medium"
            onClick={() => handleClose()}
            fullWidth
          >
            Annuler
          </MDButton>
          <MDButton
             variant='gradient'
             color='green'
             circular
            onClick={() => onsubmitUse()}
            fullWidth
            disabled = {disableSubmit}
          >
            Confirmer
          </MDButton>
        </DialogActions>
      </Dialog>


  );
}

export default ConfirmUsedTicket;