import { col, lightHighlighter, slot } from '../styles'

import { Col } from 'antd'
import React from 'react'
import { isTodaysDate } from '../../utils'

function TimeSlot(props) {
  return (
    <Col
      key={props.dateStamp}
      style={
        isTodaysDate(props.dateStamp)
          ? { ...col, ...slot, ...lightHighlighter }
          : { ...col, ...slot }
      }
      span={3}
      onClick={() => props.openAddEventModal(props.dateStamp, props.time)}
    />
  )
}

export default TimeSlot
