import 'react-toastify/dist/ReactToastify.css'
import './index.css'

import { useEffect, useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined'
import Card from '@mui/material/Card'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import DefaultProjectCard from 'examples/Cards/ProjectCards/DefaultProjectCard'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import GoogleCalendar from './googleCalendar'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import ProfileCard from 'examples/Cards/InfoCards/ProfileInfoCard/profileEdited'
import ProfileInfoCard from 'examples/Cards/InfoCards/ProfileInfoCard'
import Select from 'react-select'
import Tooltip from '@mui/material/Tooltip'
import makeAnimated from 'react-select/animated'
import { toast } from 'react-toastify'

function Classes() {
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };
  useEffect(() => {
    if (localStorage.getItem("Token")) {
      const decodedJwt = parseJwt(localStorage.getItem("Token"));
      console.log(Date.now() )
      if (decodedJwt.exp * 1000 < Date.now()) {

      localStorage.removeItem('Token')
      }
    }
    const loggedInAdmin = localStorage.getItem("Token")
    if (!loggedInAdmin) {
      window.location.replace('/') 
    }
  }, [])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant='gradient'
                bgColor='green'
                borderRadius='lg'
                coloredShadow='info'
              >
                <MDTypography variant='h4' textAlign='center' color='white'>
                  Classes
                </MDTypography>
              </MDBox>
              <MDBox>
                {' '}
                <GoogleCalendar />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  )
}

export default Classes
