import axios from 'axios'
import moment from 'moment'
const CalendarEventHandler = (function () {
  /**
   * Add event after adding meta data in the event
   * @param {arr} allEvent - Array of all the events
   * @param {Object} newEvent - Event object of the new event
   * @returns {Object} allEvents - A new object reference for all events
   */
  function addEvent(allEvents, newEvent) {
    const time = moment(new Date(newEvent.startAt)).hours()
    const eventWithMeatInfo = {
      ...newEvent,
      startWeek: moment(new Date(newEvent.startAt)).week(),
      endWeek: moment(new Date(newEvent.endAt)).week(),
    }

    if (allEvents[time]) {
      let is = true
      for (let index = 0; index < allEvents[time].length; index++) {
        if (allEvents[time][index]._id.includes(newEvent._id)) {
          is = false
        }
      }
      if (is) {
        allEvents[time].push(eventWithMeatInfo)
      }
    } else {
      allEvents[time] = [eventWithMeatInfo]
    }
    return { ...allEvents }
  }

  /**
   * Generate unique id for an event
   * @param {timeStamp} start - Start timestamp of the event
   * @param {timeStamp} end - End timeStamp of the event
   * @params {string} title - Title of the event
   * @returns {string} id - Unique id
   */
  // function generateUniqueId({ start, title, end }) {
  //  return start + title + end
  // }

  /**
   * Deletes event from the list

   * @returns {Object} allEvents - A new object reference for all events
   */
  function deleteEvent(eventId, allEvents) {
    axios
      .delete(`${process.env.REACT_APP_API_DEV}/classes/${eventId}`, {
        headers: {
          Authorization: `${localStorage.getItem('firebaseToken')}`,
        },
      })
      .catch(function (error) {
        if (error.response) {
          console.log('response')
        } else if (error.request) {
          console.log('request')
        } else {
          console.log('autre')
        }
        console.log(error.config)
      })
      .then((res) => {
        if (res.data) {
          console.log('good deleted!')
        }
      })
  }

  /**
   * Updates an event from the list
   * @param {string} eventId - Id of the event to be deleted
   * @param {Object} updatedEvent - Event objects with the updated data
   * @param {arr} allEvents - Array of all the events
   * @returns {Object} allEvents - A new object reference for all events
   */
  function updateEvent(eventId, updatedEvent, allEvents) {
    Object.keys(allEvents).forEach((time) => {
      allEvents[time] = allEvents[time].map((event) =>
        event.id === eventId ? { ...event, ...updatedEvent } : event
      )
    })
    return { ...allEvents }
  }

  return {
    add: addEvent,
    delete: deleteEvent,
    update: updateEvent,
    // generateId: generateUniqueId,
  }
})()

export default CalendarEventHandler
