import axios from 'axios';
import { toast } from 'react-toastify'

toast.configure()

async function usdTicket(ticket, handleClose, getAllData){

  const notifySuccess = () => {
    toast.success('Successful!', { autoClose: 3000 })
  }
  const notifyFailure = (msg) => {
    toast.error(msg, { autoClose: 3000 })
  }
  await axios
  .patch(`${process.env.REACT_APP_API_DEV}/tickets/useTicket`, {
    id: ticket.id,
    userId: ticket.userId,
  },
  {
    headers: {
      Authorization: `${localStorage.getItem('firebaseToken')}`,
    },
  })
  .catch(function (error) {
    if (error.response) {
     // console.log("response error")
      notifyFailure(error.response.data.message)
    } else if (error.request) {
      notifyFailure('Please retry again')
    } else {
      notifyFailure('Please retry again')

    }
   // console.log(error.config)
  })
  
  .then((res) => {
    console.log(res)
    console.log(res.data)
    if (res.data) {
    // console.log("***********", res)
      notifySuccess()
    }})
  .then(handleClose)
  .then(getAllData)


  }
  export default usdTicket;