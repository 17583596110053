import Box from '@mui/material/Box'
// Material Dashboard 2 React example components
import DefaultNavbar from 'examples/Navbars/DefaultNavbar'
// Authentication pages components
import Footer from 'layouts/authentication/components/Footer'
// @mui material components
import Grid from '@mui/material/Grid'
// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import PageLayout from 'examples/LayoutContainers/PageLayout'
// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'
import logo from 'assets/images/Yofitt Brand Assets-06.png'
function BasicLayout({ image, children }) {
  return (
    <PageLayout>
      {/* <DefaultNavbar
        action={{
          type: "external",
          route: "https://creative-tim.com/product/material-dashboard-react",
          label: "free download",
          color: "dark",
        }}
      /> */}
      <MDBox
        position='absolute'
        width='100%'
        minHeight='100vh'
        bgColor='white'
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) => image && ` url(${image})`,
          //   backgroundSize: 'cover',
          //  backgroundPosition: 'center',
          //  backgroundRepeat: 'no-repeat',
        }}
      />

      <MDBox px={1} width='100%' height='100vh' mx='auto'>
        <Grid
          container
          spacing={1}
          justifyContent='center'
          alignItems='center'
          height='100%'
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            {children}
          </Grid>
        </Grid>
      </MDBox>
      <Footer light />
    </PageLayout>
  )
}

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default BasicLayout
