/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable no-dupe-keys */
// Material Dashboard 2 React base styles
import colors from "assets/theme/base/colors";


const { gradients, dark } = colors;

function configs(labels, datasets) {
  const backgroundColors = [];

  if (datasets.backgroundColors) {
    datasets.backgroundColors.forEach((color) =>
      gradients[color]
        ? backgroundColors.push(gradients[color].state)
        : backgroundColors.push(dark.main)
    );
  } else {
    backgroundColors.push(dark.main);
  }

  return {
    data: {
      labels,
      datasets: [
        {
          label: datasets.label,
          weight: 9,
          cutout: 0,
          tension: 0.9,
          pointRadius: 2,
          borderWidth: 2,
          backgroundColor: [
            'rgba(220,20,60, 0.2)',
            'rgba(255,215,0, 0.2)',
            'rgba(0,128,128, 0.2)',
            'rgba(70,130,180, 0.2)',
            'rgba(139,0,139, 0.2)',
            'rgba(255,20,147, 0.2)',
            'rgba(139,69,19, 0.2)',
            'rgba(112,128,144, 0.2)',
            'rgba(0,0,139, 0.2)',
            'rgba(64,224,208, 0.2)',
            'rgba(128,0,0, 0.2)',
            'rgba(255,255,0, 0.2)',
            'rgba(85,107,47, 0.2)',
            'rgba(0,255,255, 0.2)',
            'rgba(70,130,180, 0.2)',
            'rgba(255,140,0, 0.2)',
            'rgba(250,128,114, 0.2)',
            'rgba(46,139,87, 0.2)',
            'rgba(0,0,128, 0.2)',
            'rgba(138,43,226, 0.2)',
            'rgba(216,191,216, 0.2)',
            'rgba(245,222,179, 0.2)',
            'rgba(188,143,143, 0.2)',
            'rgba(119,136,153, 0.2)',
            'rgba(230,230,250, 0.2)',
            'rgba(199,21,133, 0.2)',
            'rgba(135,206,235, 0.2)',
            'rgba(0,255,127, 0.2)',
            'rgba(218,165,32, 0.2)',
            'rgba(165,42,42, 0.2)',
            'rgba(255,0,255, 0.2)',
            'rgba(107,142,35, 0.2)',
            'rgba(72,209,204, 0.2)',
            'rgba(100,149,237, 0.2)',
            'rgba(75,0,130, 0.2)',
            'rgba(221,160,221, 0.2)',
            'rgba(139,69,19, 0.2)',
            'rgba(143,188,143, 0.2)',
            'rgba(127,255,0, 0.2)',
            'rgba(255,127,80, 0.2)',
          ],
          borderColor: [
            'rgba(220,20,60, 1)',
            'rgba(255,215,0, 1)',
            'rgba(0,128,128, 1)',
            'rgba(70,130,180, 1)',
            'rgba(139,0,139, 1)',
            'rgba(255,20,147, 1)',
            'rgba(139,69,19, 1)',
            'rgba(112,128,144, 1)',
            'rgba(0,0,139, 1)',
            'rgba(64,224,208, 1)',
            'rgba(128,0,0, 1)',
            'rgba(255,255,0, 1)',
            'rgba(85,107,47, 1)',
            'rgba(0,255,255, 1)',
            'rgba(70,130,180, 1)',
            'rgba(255,140,0, 1)',
            'rgba(250,128,114, 1)',
            'rgba(46,139,87, 1)',
            'rgba(0,0,128, 1)',
            'rgba(138,43,226, 1)',
            'rgba(216,191,216, 1)',
            'rgba(245,222,179, 1)',
            'rgba(188,143,143, 1)',
            'rgba(119,136,153, 1)',
            'rgba(230,230,250, 1)',
            'rgba(199,21,133, 1)',
            'rgba(135,206,235, 1)',
            'rgba(0,255,127, 1)',
            'rgba(218,165,32, 1)',
            'rgba(165,42,42, 1)',
            'rgba(255,0,255, 1)',
            'rgba(107,142,35, 1)',
            'rgba(72,209,204, 1)',
            'rgba(100,149,237, 1)',
            'rgba(75,0,130, 1)',
            'rgba(221,160,221, 1)',
            'rgba(139,69,19, 1)',
            'rgba(143,188,143, 1)',
            'rgba(127,255,0, 1)',
            'rgba(255,127,80, 1)',
          ],
          fill: false,
          data: datasets.data,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
        },
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
      scales: {
        y: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    },

  };
}

export default configs;
