/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import MDButton from "components/MDButton";

import { DatePicker, Space } from 'antd';
// Material Dashboard 2 React example components
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'

// import projectsTableData from "layouts/tables/data/projectsTableData";

import dayjs from 'dayjs';
import { useEffect, useState } from "react";
import { toast } from 'react-toastify'
const { RangePicker } = DatePicker;
function FilterTickets({open, setOpen, setFromDate, setToDate, fromDate, toDate, setFilterByDay, setActiveDay }) {
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [displaySpinner, setDisplaySpinner] = useState(false)
  const [voidRangeDate, setVoidRangeDate] = useState(false)
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
 
  const notifySuccess = () => {
    toast.success('Successful!', { autoClose: 3000 })
  }
  const notifyFailure = (msg) => {
    toast.error(msg, { autoClose: 3000 })
  }
 
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };


  const handleDateChange = (dates) => {
    console.log("clicked")
    if (dates && dates.length === 2) {
      setStartDate(new Date(dates[0]).setHours(0, 0, 0, 999));
      setEndDate(new Date(dates[1]).setHours(23, 59, 59, 999));
      setVoidRangeDate(true)
    } else {
      setVoidRangeDate(true)
      setStartDate(null);
      setEndDate(null);
    }
  };
  const handleClose = () => {
    setOpen(false)
    setEndDate(null)
    setStartDate(null)
    setDisplaySpinner(false)
    setDisableSubmit(false)
  }


  const onsubmit = async()=>{
    if (voidRangeDate) {
      setFromDate(startDate)
      setToDate(endDate)
      setFilterByDay(false)
      setActiveDay("tout")
    }
   

    handleClose()
  }



  useEffect(() => {
    if (localStorage.getItem("Token")) {
      const decodedJwt = parseJwt(localStorage.getItem("Token"));
     // console.log(Date.now() )
      if (decodedJwt.exp * 1000 < Date.now()) {

      localStorage.removeItem('Token')
      }
    } 
    const loggedInAdmin = localStorage.getItem("Token")
    if (!loggedInAdmin) {
      window.location.replace('/') 
    }
  

  }, []);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
    <DialogTitle>Filtrer les tickets</DialogTitle>
    <MDBox pt={4} pb={3} px={3}>

      <MDBox component='form' role='form'>

       <MDBox mb={2}>
        <RangePicker
        style={{ width: "100%", height:45 }}
        onChange={handleDateChange}
        defaultValue={ fromDate && toDate ? [dayjs(fromDate, 'YYYY-MM-DD'), dayjs(toDate, 'YYYY-MM-DD')] : undefined }
        />
       </MDBox>

        <MDBox mt={3} mb={1} textAlign='center'>

        </MDBox>
      </MDBox>

    </MDBox>

    
    <DialogActions>
      <MDButton
        variant='text'
        color='error'
        onClick={handleClose}
        fullWidth
      >
        Cancel
      </MDButton>
      <MDButton
       size="medium"
       color='darkgreen'
       variant='gradient'
       circular
       onClick={onsubmit}
       fullWidth
       disabled = {disableSubmit}
      >
        Save
      </MDButton>
    </DialogActions>

  </Dialog>
  );
}

export default FilterTickets;