// Authentication layout components

import 'bootstrap/dist/css/bootstrap.min.css'
import './signin.css'
// firebase
import './signin.css'

import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { useEffect, useState } from 'react'

import $ from 'jquery'
import BasicLayout from 'layouts/authentication/components/BasicLayout'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Footer from 'layouts/authentication/components/Footer'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MuiLink from '@mui/material/Link'
// @mui material components
import { Navbar } from '../components'
// react-router-dom components
import PageLayout from 'examples/LayoutContainers/PageLayout'
import Popper from 'popper.js'
import Switch from '@mui/material/Switch'
import { Typography } from '@mui/material'
import { app } from '../../../firebaseConfig'
// axios
import axios from 'axios'
// Images
import bgImage from 'assets/images/bg.png'
import logo from 'assets/images/brand.png'
const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};
// @mui icons
const image = 'https://yofitt.com/wp-content/uploads/2022/06/BG.jpg?id=2088'
function Basic() {
  const [rememberMe, setRememberMe] = useState('')

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState('')
 
  useEffect(() => {
    if (localStorage.getItem("Token")) {
      const decodedJwt = parseJwt(localStorage.getItem("Token"));
      console.log(Date.now() )
      if (decodedJwt.exp * 1000 < Date.now()) {

      localStorage.removeItem('Token')
      }
    }
    const loggedInAdmin = localStorage.getItem("Token")
    if (loggedInAdmin) {
      window.location.href = '/dashboard'
    }
  }, [])
  const onEmailChange = (e) => {
    setEmail(e.target.value)
  }
  const onPasswordChange = (e) => {
    setPassword(e.target.value)
  }
  const onsubmit = async () => {
    const auth = getAuth()
    const user = await signInWithEmailAndPassword(auth, email, password).catch(
      (error) => {
        setErrors('Email or password are incorrect')
      }
    )
    const url = `${process.env.REACT_APP_API_DEV}/users/${user.user.uid}`

    const { data: u } = await axios.get(url)
    console.log(u)
    if (user.user.emailVerified == true && u.isEmailVerified != true) {
      await axios.put(`${process.env.REACT_APP_API_DEV}/coaches/verifyEmail/${user.user.uid}`)
    }
    if (u?.role !== 'coach') {
      setErrors('You do not have the authorization to login')
      console.log(errors)
    } else {
      console.log('enter')
      const authData = {
        id: u?._id,
        displayName: u?.displayName,
        email: u?.email,
        token: await user.user.getIdToken(),
        photoURL: u?.photoURL,
        tokenIds: u?.tokenIds,
      }
      await auth.currentUser
        .getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          console.log('*********', authData.tokenIds)
          localStorage.setItem('firebaseToken', idToken)
          localStorage.setItem('test', authData.tokenIds)
        })
        .catch(function (error) {
          console.log(error)
        })

      localStorage.setItem('Token', authData.token)
      localStorage.setItem('user', JSON.stringify(u))
      window.location.href = '/dashboard'
    }
  }
  return (
    <PageLayout>
      <MDBox
        px={1}
        width='100%'
        bgColor='white'
        height='100vh'
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) => image && `url(${image})`,
          backgroundSize: '120% !important',
          backgroundPosition: '45% -250px !important',
          backgroundRepeat: 'no-repeat',
        }}
        mx='auto'
      >
        <Navbar />
        <Grid
          container
          spacing={1}
          justifyContent='center'
          alignItems='center'
          height='70%'
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Typography color='#000000' variant='h2' fontWeight='bold'>
              Sign in to manage your gym
            </Typography>
            <Box>
              <MDBox
                //coloredShadow='dark'
                //pt={4}
                //pb={3}
                //px={3}
                marginTop={10}
              >
                <MDBox marginTop={2} component='form' role='form'>
                  <MDBox mb={2}>
                    <MDInput
                      className='inputRounded'
                      type='email'
                      label='Email'
                      onChange={onEmailChange}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox marginTop={3}>
                    <MDInput
                      className='inputRounded'
                      type='password'
                      label='Password'
                      onChange={onPasswordChange}
                      circular
                      fullWidth
                    />
                  </MDBox>
                  <MDBox marginTop={3}>
                    <MDButton
                      variant='gradient'
                      color='green'
                      size='large'
                      onClick={onsubmit}
                      fullWidth
                      circular
                    >
                      sign in
                    </MDButton>
                  </MDBox>
                  <MDBox mt={3} mb={1} textAlign='center'>
                    <MDTypography variant='button' color='error'>
                      {errors}
                    </MDTypography>
                  </MDBox>
                  <MDBox marginTop={3}>
                    <MDButton
                      size='large'
                      variant='text'
                      color='white'
                      fullWidth
                    >
                      <MDTypography
                        variant='d8'
                        textTransform='uppercase'
                        fontWeight='bold'
                      >
                        <a className='url' href='https://yofitt.com'>
                          go to website
                        </a>
                      </MDTypography>
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Box>
          </Grid>
        </Grid>
      </MDBox>
      <Footer light />
    </PageLayout>
  )
}

export default Basic
