/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from 'components/MDInput'
import Select from 'react-select'
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import axios from 'axios';
// Material Dashboard 2 React example components
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import { toast } from 'react-toastify'
import markAsNoShow from "../functions/markAsNoShow";
import { useEffect, useState } from "react";

toast.configure()
function ConfirmNoShow({open, setOpen, ticket, getAllData}) {
  const [disableSubmit, setDisableSubmit] = useState(false)
  const handleClose = () => {
    setOpen(false)
    setDisableSubmit(false)
  }
  const styles = {
    height: 200,
  }
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

const onsubmit = async()=>{
  setDisableSubmit(true)
    await markAsNoShow(ticket, handleClose, getAllData)
}

  useEffect(() => {
    if (localStorage.getItem("Token")) {
      const decodedJwt = parseJwt(localStorage.getItem("Token"));
      if (decodedJwt.exp * 1000 < Date.now()) {

      localStorage.removeItem('Token')
      }
    }
    const loggedInAdmin = localStorage.getItem("Token")
    if (!loggedInAdmin) {
      window.location.replace('/') 
    }
  
  }, []);

  return (
    <Dialog open={open}  onClose={handleClose} fullWidth
    maxWidth="xs">
    <DialogTitle>Marquer no show</DialogTitle>
    <MDBox pt={2} py={-10} pb={3} px={3}>
      <MDTypography variant='button' textTransform="none" color='black'>
      {"Voulez-vous marquer le status du ticket de "+ticket?.UserName +" comme no show?"}
      </MDTypography>
    </MDBox>

    <DialogActions>
      <MDButton
        variant='text'
        color='error'
        size = "medium"
        onClick={() => handleClose()}
        fullWidth
      >
        Cancel
      </MDButton>
      <MDButton

        variant='gradient'
        color='green'
        circular
        onClick={onsubmit}
        fullWidth
        disabled = {disableSubmit}
      >
        Confirm
      </MDButton>
    </DialogActions>
  </Dialog>


  );
}

export default ConfirmNoShow;