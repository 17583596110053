/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment'
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import './index.css'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Pagination from './pagination';
// Material Dashboard 2 React example components
import TimelineItem from "examples/Timeline/TimelineItem";

function OrdersOverview() {
  const [TickersData, setTickersData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [postPerPage, setPostPerPage] = useState(4)
  const [pages, setpages] = useState([])
  const colors = {
    pending: 'orange',
    refused: 'error',
    accepted: 'green',
    undefined: 'secondary',
  }  
  const getDataTickets = async () => {
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/tickets/all-by-status-by-gym`, {
      headers: {
        Authorization: `${localStorage.getItem('firebaseToken')}`,
      },
    })
    .then((res) => {
      const Tickets = res.data
      const day = new Date()
      let i = 0
      for (let index = 0; index < Tickets.length; index++) {
       if(i < 16){
          TickersData.push(Tickets[index])
          i++
       }
      }
      
    }).then(()=>{
      for (let i = 1; i <= Math.ceil(TickersData.length / postPerPage); i++) {
        pages.push(i);
    }
    })
   }
   const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };
  useEffect(() => {
    if (localStorage.getItem("Token")) {
      const decodedJwt = parseJwt(localStorage.getItem("Token"));
      console.log(Date.now() )
      if (decodedJwt.exp * 1000 < Date.now()) {

      localStorage.removeItem('Token')
      }
    }
    const loggedInAdmin = localStorage.getItem("Token")
    if (!loggedInAdmin) {
      window.location.replace('/') 
    }
    getDataTickets()

  }, [])

  const lastPostIndex = currentPage * postPerPage
  const firstPstIndex = lastPostIndex - postPerPage
  const currentPosts = TickersData.slice(firstPstIndex, lastPostIndex)
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Recent bookings 
        </MDTypography>

      </MDBox>
      <MDBox p={2}>
      {currentPosts  && currentPosts .map((item) => (

        <TimelineItem
          color={colors[item?.availabilityStatus ? item?.availabilityStatus:"undefined"]}
          title={item.classId.title +' - '+moment(new Date(item.classId.startAt)).format('YYYY-MM-DD') + ' at '+moment(new Date(item.classId.startAt).getTime()).format('hh:mm a')}
          dateTime={'booked ' + moment(new Date(item.createdAt)).format('YYYY-MM-DD') + ' at '+moment(new Date(item.createdAt).getTime()).format('hh:mm a')}
          status={item.availabilityStatus}
        />
      ))}
     
      </MDBox>
      <MDBox className="pagination" >
      {pages && pages.map((page, index) =>  (
                    <button
                        key={index}
                        onClick={() => setCurrentPage(page)}
                        className={page == currentPage ? "active" : ""}>
                        {page}
                    </button>
                )
            )}
      </MDBox>
     
      
    </Card>
  );
}

export default OrdersOverview;
