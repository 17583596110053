import React, { useEffect, useState } from 'react'

import { Checkbox } from 'antd'
import ClassInfoCard from 'examples/Cards/InfoCards/ProfileInfoCard/classInfoCard'
import { DatePicker } from 'antd'
import { Description } from '@mui/icons-material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import EventHighlighter from './EventHighlighter'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MenuItem from '@mui/material/MenuItem'
import { Modal } from 'antd'
import Select from 'react-select'
import Tooltip from '@mui/material/Tooltip'
import { eventHighlighter } from '../styles'
import { generateWeekView } from '../../utils'
import moment from 'moment'

function MoreEvents(props) {
  const [showClassModal, setShowClassModal] = useState(false)
  const openClassModal = () => {

    setShowClassModal(true)
  }
  const closeModal = () => {
    setShowClassModal(false)
  }
 // console.log('hani d5elt l event', props.event)
  return (
    <React.Fragment>
      <Modal
        width={900}
        visible={showClassModal}
        //onOk={this.handleOk}
        onCancel={closeModal}
        footer={[]}
      >
        {props.events.map(
          (event) =>
            moment(new Date(event.startAt).getTime()).week() <=
              moment(props.startDate).week() &&
            moment(new Date(event.startAt).getTime()).week() >=
              moment(props.startDate).week() && (
              <EventHighlighter
                onEventDelete={props.onEventDelete}
                onEventUpdate={props.onEventUpdate}
                onNewEvent={props.onNewEvent}
                key={
                  event.title +
                  new Date(event.endAt).getTime() +
                  new Date(event.startAt).getTime() +
                  event._id
                }
                startDate={props.startDate}
                event={event}
                events={props.ev}
                index={props.events.indexOf(event)}
                long={props.events.length}
                multi={true}
              />
            )
        )}
      </Modal>
      <Tooltip title='Click to view' placement='bottom'>
        <div
          onClick={() => openClassModal()}
          style={{
            ...generateWeekView(props.startDate, props.event),
            ...eventHighlighter,
          }}
        >
          {props.long} Classes
          <br />
        </div>
      </Tooltip>
    </React.Fragment>
  )
}

export default MoreEvents
