/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import moment from 'moment'
import Card from "@mui/material/Card";
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import PhotoCamera from '@mui/icons-material/PhotoCamera';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from 'components/MDInput'
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import axios from 'axios';
import { DatePicker, Space } from 'antd';
// Material Dashboard 2 React example components
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Select from 'react-select'

// import projectsTableData from "layouts/tables/data/projectsTableData";

import dayjs from 'dayjs';
import { useEffect, useState } from "react";
import { toast } from 'react-toastify'
// Create the file metadata
/** @type {any} */
const metadata = {
  contentType: 'image/jpeg'
};
const { RangePicker } = DatePicker;
function EXtractTickets({open, setOpen }) {
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [displaySpinner, setDisplaySpinner] = useState(false)
  const [voidRangeDate, setVoidRangeDate] = useState(false)
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [errors, setErrors] = useState("")
  const { RangePicker } = DatePicker;
  const notifySuccess = () => {
    toast.success('Successful!', { autoClose: 3000 })
  }
  const notifyFailure = (msg) => {
    toast.error(msg, { autoClose: 3000 })
  }
 
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };



  



  const handleDateChange = (dates) => {
 
    if (dates && dates.length === 2) {
      setStartDate(new Date(dates[0]).setHours(0, 0, 0, 999));
      setEndDate(new Date(dates[1]).setHours(23, 59, 59, 999));
      setVoidRangeDate(true)
    } else {
      setVoidRangeDate(true)
      setStartDate(null);
      setEndDate(null);
    }
  };
  const handleClose = () => {
    setOpen(false)
    setEndDate(null)
    setStartDate(null)
    setDisplaySpinner(false)
    setDisableSubmit(false)
    setErrors("")
  }


  const onsubmit = async()=>{
    if (startDate && endDate) {
        try {
            const response = await axios
              .get(
                `${process.env.REACT_APP_API_DEV}/tickets/export-tickets?fromDate=${new Date(startDate)}&toDate=${new Date(endDate)}`,
                {
                  headers: {
                    Authorization: `${localStorage.getItem('firebaseToken')}`,
                  },
                }, {
                    responseType: 'blob', 
                  })
        
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'tickets.csv');
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
            handleClose()
              }catch (error) {
                notifyFailure(`Error exporting CSV: ${error}`)
                handleClose()
              }
    }else{
        setErrors("Please select the date range")
    }
   
  }



  useEffect(() => {
    if (localStorage.getItem("Token")) {
      const decodedJwt = parseJwt(localStorage.getItem("Token"));
     // console.log(Date.now() )
      if (decodedJwt.exp * 1000 < Date.now()) {

      localStorage.removeItem('Token')
      }
    } 
    const loggedInAdmin = localStorage.getItem("Token")
    if (!loggedInAdmin) {
      window.location.replace('/') 
    }

  }, []);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
    <DialogTitle>Extract Tickets</DialogTitle>
    <MDBox pt={4} pb={3} px={3}>

      <MDBox component='form' role='form'>


       <MDBox mb={2}>
        <RangePicker
        style={{ width: "100%", height:45 }}
        onChange={handleDateChange}
       // defaultValue={ fromDate && toDate ? [dayjs(fromDate, 'YYYY-MM-DD'), dayjs(toDate, 'YYYY-MM-DD')] : undefined }
        />
       </MDBox>

       <MDBox mt={3} mb={1} textAlign='center'>
          <MDTypography variant='button' textTransform="none" color='error'>
            {errors}
          </MDTypography>
        </MDBox>
      </MDBox>

    </MDBox>


   <DialogActions>
      <MDButton
        variant='text'
        color='error'
        onClick={handleClose}
        fullWidth
      >
        Cancel
      </MDButton>
      <MDButton
        size="medium"
        color='darkgreen'
        variant='gradient'
        circular
        onClick={onsubmit}
        fullWidth
        disabled = {disableSubmit}
      >
        Save
      </MDButton>
    </DialogActions>

  </Dialog>
  );
}

export default EXtractTickets;