/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import './input.css'

// Custom styles for MDInput
import MDInputRoot from 'components/MDInput/MDInputRoot'
// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
const MDInput = forwardRef(
  ({ error, success, circular, disabled, ...rest }, ref) => (
    <MDInputRoot
      {...rest}
      ref={ref}
      ownerState={{ error, circular, success, disabled }}
    />
  )
)

// Setting default values for the props of MDInput
MDInput.defaultProps = {
  error: false,
  success: false,
  disabled: false,
  circular: false,
}

// Typechecking props for the MDInput
MDInput.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
  circular: PropTypes.bool,
}

export default MDInput
