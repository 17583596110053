import moment from 'moment'

/**
 * Generate all days in a week
 * @param {moment} currentDate - Any date in the week
 * @returns {array} days - All days in the week with date, dateStamp and weekDayName
 */
export const getAllDaysInTheWeek = (currentDate = moment()) => {
  const weekStart = currentDate.clone().startOf('week')

  const days = Array.from(Array(7))
    .map((day, index) => index)
    .map((day) =>
      moment(weekStart).add(day, 'days').set('minutes', 0).set('seconds', 0)
    )
    .map((momentObj) => ({
      date: momentObj.date(),
      dateStamp: +momentObj,
      weekDayName: momentObj.format('ddd'),
    }))

  return days
}

// All the hours in the day
export const times = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23,
]

/**
 * Generated coordinated for the highlight
 * @param {Object} event - Event object
 * {
 *  start: Time stamp for the start of the event :timeStamp,
 *  title: Title fo the new event :String,
 *  end: Time stamp for the end of the event :timeStamp,
 * }
 * @param {timeStamp} startDate - Timestamp of any date in the current week
 * @returns {Object} coordinates - Coordinates object with
 * {
 *  top - CSS top of the element,
 *  left - CSS left of the element,
 *  width - CSS width of the element,
 *  height - CSS height of the element
 * }
 */
export const generateWeekViewCoordinates = (
  event,
  startDate,
  long,
  index,
  events
) => {
  const start = moment(new Date(event.startAt))
  const end = moment(new Date(event.endAt))
  const table = []
  const duration = moment.duration(end.diff(start))
  const weekStart = moment(startDate)
  const time = moment(new Date(event.startAt)).hours()
  let longueur = 0

  const colors = [
    '#FFBB00',
    '#26d98c',
    '#EF5350',
    '#9ca09f',
    '#8097bf',
    '#66BB6A',
  ]
  let color = 'black'
  // Calculating Top
  const top = start.minutes() === 30 ? '50%' : '0%'
  for (let index = 0; index < events[time].length; index++) {
    if (
      moment(new Date(events[time][index].startAt)).day() === start.day() &&
      moment(new Date(events[time][index].startAt)).week() === start.week()
    ) {
      longueur++
      table.push(events[time][index])
    }
  }

  // Calculating height
  const timeFactor = duration.hours() + duration.minutes() / 60
  const height = timeFactor * 100

  let left, width

  if (weekStart.week() === start.week() && weekStart.week() === end.week()) {
    const daysDiff = duration.days()
    width = ((daysDiff + 1) * 12.5 - 1) / longueur
  }
  if (weekStart.week() === start.week()) {
    const weekDay = start.weekday()
    left = (weekDay + 1) * 12.5 + table.indexOf(event) * width
  }

  if (weekStart.week() > start.week() && weekStart.week() === end.week()) {
    const daysDiff = moment
      .duration(
        end.diff(
          weekStart
            .startOf('week')
            .set('hours', start.hours())
            .set('minutes', start.minutes())
        )
      )
      .days()
    width = (daysDiff + 1) * 12.5 - 2
  }

  if (weekStart.week() > start.week()) {
    left = 12.5
  }

  if (weekStart.week() < end.week()) {
    width = 100 - left
  }
  if (event.status.includes('pending')) {
    color = colors[0]
  } else if (event.status.includes('available')) {
    color = colors[1]
  } else if (event.status.includes('refused')) {
    color = colors[2]
  } else if (event.status.includes('blocked')) {
    color = colors[3]
  } else if (event.status.includes('full')) {
    color = colors[4]
  } else if (event.status.includes('completed')) {
    color = colors[5]
  }

  return {
    top: top + '%',
    left: left + '%',
    height: height + '%',
    width: width + '%',
    backgroundColor: color,
  }
}
export const generateWeekView = (startDate, event) => {
  const start = moment(new Date(event.startAt))
  const end = moment(new Date(event.endAt))
  const table = []
  const duration = moment.duration(end.diff(start))
  const weekStart = moment(startDate)
  const time = moment(new Date(event.startAt)).hours()
  let longueur = 0

  const colors = [
    '#2699fb',
    '#26d98c',
    '#ff003b',
    '#9ca09f',
    'rgba(66,103,178,1)',
    'rgba(239,244,242,1)',
  ]
  let color = 'black'
  // Calculating Top
  const top = start.minutes() === 30 ? '50%' : '0%'

 // console.log('indice', table.indexOf(event))
  // Calculating height
  const timeFactor = duration.hours() + duration.minutes() / 60
  const height = timeFactor * 100

  let left, width

  if (weekStart.week() === start.week() && weekStart.week() === end.week()) {
    const daysDiff = duration.days()
    width = ((daysDiff + 1) * 12.5 - 1) / longueur
  }
  if (weekStart.week() === start.week()) {
    const weekDay = start.weekday()
    left = (weekDay + 1) * 12.5 + table.indexOf(event) * width
  }

  if (weekStart.week() > start.week() && weekStart.week() === end.week()) {
    const daysDiff = moment
      .duration(
        end.diff(
          weekStart
            .startOf('week')
            .set('hours', start.hours())
            .set('minutes', start.minutes())
        )
      )
      .days()
    width = (daysDiff + 1) * 12.5 - 2
  }

  if (weekStart.week() > start.week()) {
    left = 12.5
  }

  if (weekStart.week() < end.week()) {
    width = 100 - left
  }
  if (event.status.includes('pending')) {
    color = colors[0]
  } else if (event.status.includes('available')) {
    color = colors[1]
  } else if (event.status.includes('refused')) {
    color = colors[2]
  } else if (event.status.includes('blocked')) {
    color = colors[3]
  } else if (event.status.includes('full')) {
    color = colors[4]
  } else if (event.status.includes('completed')) {
    color = colors[5]
  }

  return {
    top: top + '%',
    left: left + '%',
    height: height + '%',
    width: width + '%',
    backgroundColor: color,
  }
}
/**
 * Checks if the dateStamp represents todays date
 * @param {dateStamp} dateStamp - Date Stamp to check
 * @return {boolean}
 */
export const isTodaysDate = (dateStamp) => {
  const today = moment()
  dateStamp = moment(dateStamp)
  return (
    moment.duration(dateStamp.diff(today)).days() === 0 &&
    today.day() === dateStamp.day()
  )
}
