/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eseslint-disable */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { useEffect, useState } from 'react'
import moment from 'moment'
// import MDAvatar from "components/MDAvatar";
import MDBadge from 'components/MDBadge'
// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { app } from '../../../firebaseConfig'
import axios from 'axios'
import pxToRem from 'assets/theme/functions/pxToRem'

export default function Data(TickersData) {

  return {
    columns: [
      // { Header: "status", accessor: "status", align: "left" },
      {
        title: 'Username',
        field: 'UserName',
        cellStyle: {
          whiteSpace: 'nowrap',
          lineHeight: 1.6,
          fontSize: pxToRem(16),
          fontWeight: 300,
          fontFamily: ' "Arial", "Helvetica", sans-serif',
        },
        headerStyle: {
          backgroundColor: '#EFF4F2',
          whiteSpace: 'nowrap',
          color: 'black',
        },
      },

      {
        title: 'Class name',
        field: 'className',
        cellStyle: {
          whiteSpace: 'nowrap',
          lineHeight: 1.6,
          fontSize: pxToRem(16),
          fontWeight: 300,
          fontFamily: ' "Arial", "Helvetica", sans-serif',
        },
        headerStyle: {
          backgroundColor: '#EFF4F2',
          color: 'black',
          whiteSpace: 'nowrap',
        },
      },
      {
        title: 'Status',
        field: 'status',
        cellStyle: {
          whiteSpace: 'nowrap',
        },
        render: (rowData) => {
          return rowData.status === 'used' ? (
            <MDBadge
              badgeContent={rowData.status}
              color='warning'
              variant='gradient'
              size='md'
            />
          ) : rowData.status === 'available' ? (
            <MDBadge
              badgeContent={rowData.status}
              color='success'
              variant='gradient'
              size='md'
            />
          ) : (
            <MDBadge
              badgeContent={rowData.status}
              color='error'
              variant='gradient'
              size='md'
            />
          )
        },
        headerStyle: {
          backgroundColor: '#EFF4F2',
          color: 'black',
        },
      },
      {
        title: 'Availability status',
        field: 'availability',
        headerStyle: {
          backgroundColor: '#EFF4F2',
          color: 'black',
          whiteSpace: 'nowrap',
        },
        cellStyle: {
          whiteSpace: 'nowrap',
        },
        render: (rowData) => {
          return rowData.availability === 'pending' ? (
            <MDBadge
              badgeContent={rowData.availability}
              color='warning'
              variant='gradient'
              size='md'
            />
          ) : rowData.availability === 'accepted' ? (
            <MDBadge
              badgeContent={rowData.availability}
              color='success'
              variant='gradient'
              size='md'
            />
          ) : (
            <MDBadge
              badgeContent={rowData.availability}
              color='error'
              variant='gradient'
              size='md'
            />
          )
        },
        headerStyle: {
          backgroundColor: '#EFF4F2',
          color: 'black',
        },
      },
      {
        title: 'Class date',
        field: 'classDate',
        cellStyle: {
          whiteSpace: 'nowrap',
          lineHeight: 1.6,
          fontSize: pxToRem(16),
          fontWeight: 300,
          fontFamily: ' "Arial", "Helvetica", sans-serif',
        },
        headerStyle: {
          backgroundColor: '#EFF4F2',
          whiteSpace: 'nowrap',
          color: 'black',
        },
      },
      {
        title: 'Price TTC',
        field: 'classPrice',
        cellStyle: {
          whiteSpace: 'nowrap',
          lineHeight: 1.6,
          fontSize: pxToRem(16),
          fontWeight: 300,
          fontFamily: ' "Arial", "Helvetica", sans-serif',
        },
        headerStyle: {
          backgroundColor: '#EFF4F2',
          whiteSpace: 'nowrap',
          color: 'black',
        },
        hidden:true
      },
      {
        title: 'Date of booking',
        field: 'createdAt',
        cellStyle: {
          whiteSpace: 'nowrap',
          lineHeight: 1.6,
          fontSize: pxToRem(16),
          fontWeight: 300,
          fontFamily: ' "Arial", "Helvetica", sans-serif',
        },
        headerStyle: {
          backgroundColor: '#EFF4F2',
          color: 'black',
          whiteSpace: 'nowrap',
        },
      },
      { title: "ticketDoc", field: "ticketDoc", hidden: true},
      {
        title: 'Ticket id',
        field: 'id',
        cellStyle: {
          whiteSpace: 'nowrap',
          lineHeight: 1.6,
          fontSize: pxToRem(16),
          fontWeight: 300,
          fontFamily: ' "Arial", "Helvetica", sans-serif',
        },
        headerStyle: {
          backgroundColor: '#EFF4F2',
          whiteSpace: 'nowrap',
          color: 'black',
        },
      },
      {
        title: 'User Id',
        field: 'userId',
        hidden: true,
        cellStyle: {
          whiteSpace: 'nowrap',
          lineHeight: 1.6,
          fontSize: pxToRem(16),
          fontWeight: 300,
          fontFamily: ' "Arial", "Helvetica", sans-serif',
        },
        headerStyle: {
          backgroundColor: '#EFF4F2',
          whiteSpace: 'nowrap',
          color: 'black',
        },
      },
      {
        title: 'Date',
        field: 'date',
        hidden: true,
        cellStyle: {
          whiteSpace: 'nowrap',
          lineHeight: 1.6,
          fontSize: pxToRem(16),
          fontWeight: 300,
          fontFamily: ' "Arial", "Helvetica", sans-serif',
        },
        headerStyle: {
          backgroundColor: '#EFF4F2',
          whiteSpace: 'nowrap',
          color: 'black',
        },
      },
      { title: 'classStartAt', field: 'classStartAt', hidden: true },
    ],

    rows: TickersData.map((item) => {
      return {
        classPrice: item.classId?.price,
        createdAt:`${moment(new Date(item.createdAt)).format('YYYY-MM-DD')} At ${moment(new Date(item.createdAt).getTime()).format('HH:mm')}`,
        id: item._id,
        status: item.status,
        availability: item.availabilityStatus,
        UserName: item.userId?.displayName,
        userId: item.userId?._id,
        className: item.classId?.title,
        date:new Date(item.classId?.startAt),
        classStartAt:item.classId?.startAt,
        ticketDoc: item,
        classDate:`${moment(new Date(item.classId?.startAt)).format('YYYY-MM-DD')} At ${moment(new Date(item.classId?.startAt).getTime()).format('HH:mm')}`,
      }
    }),
  }
}
