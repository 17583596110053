/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from 'react'
// @mui material components
import moment from 'moment'
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";

import axios from 'axios'
import { async } from '@firebase/util';
import { indexof } from 'stylis';

export default function Data() {
  const [classes, setClasses] = useState([])
  const [pendingTickets, setPendingTickets] = useState([])
  const [confirmedTickets, setConfirmedTickets] = useState([])
  const [obj1, setObj1] = useState({})
  const [obj2, setObj2] = useState({})
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };
  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  let pending = 0
  let confirmed = 0
 
  const count = async() => {
    await axios
      .get(`${process.env.REACT_APP_API_DEV}/tickets/all-by-status-by-gym`, {
        headers: {
          Authorization: `${localStorage.getItem('firebaseToken')}`,
        },
      })
      .then((res) => {
        const Tickets = res.data
        console.log("test",classes)
        for (let index = 0; index < classes.length; index++) {
          
          pending=0
          confirmed = 0
          for (let i = 0; i < Tickets.length; i++) {
              if (Tickets[i].classId._id.includes(classes[index]._id) && Tickets[i].availabilityStatus ==="pending" && Tickets[i].status!="canceled") {
                pending++
              
              }else if(Tickets[i].classId._id.includes(classes[index]._id) && Tickets[i].availabilityStatus.includes("accepted") && !Tickets[i].status.includes("canceled")){
                    confirmed++
              }
             }
             pendingTickets.push(pending)
             confirmedTickets.push(confirmed)
           }
      })

     }
  const getClasses = async() => {
    await axios
        .get(`${process.env.REACT_APP_API_DEV}/classes/by-gym`, {
          headers: {
            Authorization: `${localStorage.getItem('firebaseToken')}`,
          },
        })
        .then((res) => {
          const g = res.data
          let date = new Date()
          for (let index = 0; index < g.length; index++) {
            if(date.toLocaleDateString(options).includes(new Date(g[index].startAt).toLocaleDateString(options)) && g[index].status != "blocked"){
             classes.push(g[index]) 
            } 
          }
          

        })

        await axios
        .get(`${process.env.REACT_APP_API_DEV}/tickets/all-by-status-by-gym`, {
          headers: {
            Authorization: `${localStorage.getItem('firebaseToken')}`,
          },
        })
        .then((res) => {
          const Tickets = res.data
          console.log("test",Tickets)
          for (let index = 0; index < classes.length; index++) {
            
            pending=0
            confirmed = 0
            for (let i = 0; i < Tickets.length; i++) {
                if (Tickets[i].classId._id.includes(classes[index]._id) && Tickets[i].availabilityStatus ==="pending" && Tickets[i].status!="canceled") {
                  pending++
                
                }else if(Tickets[i].classId._id.includes(classes[index]._id) && Tickets[i].availabilityStatus.includes("accepted") && !Tickets[i].status.includes("canceled")){
                      confirmed++
                }
               }
               pendingTickets.push(pending)
               confirmedTickets.push(confirmed)
             }
             setObj1(Object.assign({}, pendingTickets))
             setObj2( Object.assign({}, confirmedTickets))

             
        })
        
    }
  useEffect(() => {
    if (localStorage.getItem("Token")) {
      const decodedJwt = parseJwt(localStorage.getItem("Token"));
      console.log(Date.now() )
      if (decodedJwt.exp * 1000 < Date.now()) {

      localStorage.removeItem('Token')
      }
    }
    const loggedInAdmin = localStorage.getItem("Token")
    if (!loggedInAdmin) {
      window.location.replace('/') 
    }
    axios.defaults.headers.common['Authorization'] = `${localStorage.getItem(
      'firebaseToken'
    )}`
    getClasses()
  //  count()

  }, [])

  return {
    columns: [
      { Header: "Title", accessor: "Class", width: "15%", align: "left" },
      { Header: "Time", accessor: "Time", width: "10%", align: "center" },
      { Header: "Available places", accessor: "Available", align: "center" },
      { Header: "Confirmed bookings", accessor: "Confirmed", align: "center" },
      { Header: "Pending bookings", accessor: "Pending", align: "center" },
    ],
    rows: classes.map((item) => {

      return {
       
        Class: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.title}
          </MDTypography>
        ),
        Time: (
          <MDBox display="flex" py={1}>
          
 {  moment(new Date(item.startAt).getTime()).format('hh:mm a')}
          </MDBox>
        ),
        Available: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.yfTicketsCount}
          </MDTypography>
        ),
        Confirmed: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {confirmedTickets[classes.indexOf(item)]}
          </MDTypography>
        ),
        Pending: (
          <MDTypography variant="caption" color="text" fontWeight="medium">

            {pendingTickets[classes.indexOf(item)]}
          </MDTypography>
        )
      }
    }),


    
  };
}
