import 'react-toastify/dist/ReactToastify.css'

import { useEffect, useState } from 'react'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CloseIcon from '@mui/icons-material/Close'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
// @mui material components
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
// Material Dashboard 2 React example components
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MaterialTable from 'material-table'
//axios
import axios from 'axios'
import brand from 'assets/images/logo@2x.png'
import { toast } from 'react-toastify'
// Data
import usersTableData from 'layouts/users/data/usersTableData'

toast.configure()
const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};
function Users() {
  const [open, setOpen] = useState(false)
  const [email, setEmail] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState('')
  const [errors1, setErrors1] = useState('')
  const [errors2, setErrors2] = useState('')
  const [openEdit, setOpenEdit] = useState(false)
  const [openDel, setOpenDel] = useState(false)
  const [idEdit, SetIdEdit] = useState('')
  const [idDel, SetIdDel] = useState('')
  const [UsersData, setUserdsData] = useState([])
  const [editDisplayName, setEditDisplayName] = useState('')
  let columns = (usersTableData(UsersData).columns)
  let rows = (usersTableData(UsersData).rows)

  const notifySuccess = () => {
    toast.success('Successfull!', { autoClose: 3000 })
  }
  const notifyFailure = (msg) => {
    toast.error(msg, { autoClose: 3000 })
  }
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setOpenDel(false)
    setOpenEdit(false)
    setErrors(' ')
    setErrors1('')
    setErrors2('')
  }
  const onDisplayNameChange = (e) => {
    setDisplayName(e.target.value)
  }
  const onEmailChange = (e) => {
    setEmail(e.target.value)
  }
  const onPasswordChange = (e) => {
    setPassword(e.target.value)
  }
  const handleEdit = (i, e, d) => {

    SetIdEdit(i)
    setEmail(e)
    setErrors('')
    setDisplayName(d)
    setOpenEdit(true)
  }
  const handleDelete = async (id, name) => {
    SetIdDel(id)
    setEditDisplayName(name)
    setOpenDel(true)
  }
  const onsubmitEdit = async () => {
    if (!email || !displayName) {
      setErrors('Please fill all the information.')
    } else if (!isValidEmail(email)) {
      setErrors('Please enter a valid email.')
    } else {
      setErrors('')
      setData()
    }
  }
  const onsubmitDel = async () => {
    await axios
      .delete(`${process.env.REACT_APP_API_DEV}/staffs/${idDel}`, {
        headers: {
          Authorization: `${localStorage.getItem('firebaseToken')}`,
        },
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.message)
          notifyFailure('Please retry again.')
          setOpen(false)
        } else if (error.request) {
          console.log(error.message)
          notifyFailure('Please retry again.')
          setOpen(false)
        } else {
          notifyFailure('Please retry again.')
          setOpen(false)
        }
        console.log(error.config)
      })
      .then((res) => res.data)
      .then(() => setOpenDel(false))
      .then(notifySuccess)
      .then(getData)

  }
  const setData = async () => {
    await axios
      .put(
        `${process.env.REACT_APP_API_DEV}/staffs/${idEdit}`,
        {
          displayName: displayName,
          email: email,
        },
        {
          headers: {
            Authorization: `${localStorage.getItem('firebaseToken')}`,
          },
        }
      )
      .catch(function (error) {
        if (error.response) {
          console.log('response', error.response)
          if(error.response.data.statusCode === 500){
            setErrors('Email already exist.')
          }else{
            notifyFailure('Please retry again.')
          }

        } else if (error.request) {
          console.log('request')
          notifyFailure('Please retry again.')
          setOpen(false)
        } else {
          console.log('autre')
          notifyFailure('Please retry again.')
          setOpen(false)
        }
        console.log(error.config)
      })
      .then((res) => res.data)
      .then(() => setOpen(false))
      .then(notifySuccess)
      .then(getData)


    handleClose()
  }
  const getData = async () => {
    setOpenDel(false)
    setOpen(false)
    setOpenEdit(false)
    axios
    .get(`${process.env.REACT_APP_API_DEV}/staffs/`, {
      headers: {
        Authorization: `${localStorage.getItem('firebaseToken')}`,
      },
    })
    .then((res) => {
      const users = res.data
      console.log(users)
      setUserdsData(users)
    })
    columns = (usersTableData(UsersData).columns)
    rows = (usersTableData(UsersData).rows)
  }
  useEffect(() => {
    if (localStorage.getItem("Token")) {
      const decodedJwt = parseJwt(localStorage.getItem("Token"));
      console.log(Date.now() )
      if (decodedJwt.exp * 1000 < Date.now()) {

      localStorage.removeItem('Token')
      }
    }
    const loggedInAdmin = localStorage.getItem("Token")
    if (!loggedInAdmin) {
      window.location.replace('/') 
    }
    axios.defaults.headers.common['Authorization'] = `${localStorage.getItem(
      'firebaseToken'
    )}`
    getData()
    console.log('from use effect')


  }, [])
  const sendData = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_DEV}/staffs`,
        {
          displayName: String(displayName),
          email: String(email),
          password: String(password),
        },
        {
          headers: {
            Authorization: `${localStorage.getItem('firebaseToken')}`,
          },
        }
      )
      .catch(function (error) {
        if (error.response) {
          setErrors('Email already exists.')
        } else if (error.request) {
          notifyFailure('Please retry again.')
          setOpen(false)
        } else {
          notifyFailure('Please retry again.')
          setOpen(false)
        }
        console.log(error.config)
      })
      .then((res) => res.data)
  }
  function isValidEmail(e) {
    return /\S+@\S+\.\S+/.test(e)
  }
  const onsubmit = (e) => {
    if (
      (!email && !displayName && !password) ||
      (!email && !displayName) ||
      (!email && !password) ||
      (!password && !displayName)
    ) {
      setErrors('Please fill all the information.')
      setErrors1('')
      setErrors2('')
      console.log(errors)
    } else if (!email) {
      setErrors2('')
      setErrors('')
      setErrors1('Please enter the email.')
    } else if (!displayName) {
      setErrors1('')
      setErrors('')
      setErrors2('Please enter the display name.')
    } else if (!password) {
      setErrors1('')
      setErrors2('')
      setErrors('Please enter the password')
    } else if (!isValidEmail(email)) {
      setErrors1('')
      setErrors2('')
      setErrors('Please enter a valid email.')
    } else if (password.length < 8) {
      setErrors1('')
      setErrors2('')
      setErrors('Password must be more than 8 characters')
    } else {
      e.preventDefault()
      sendData()
        .then(() => setOpen(false))
        .then(notifySuccess)
        .then(getData)
    }
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox textAlign='right'>
        <MDButton
          variant='gradient'
          size='large'
          color='darkgreen'
          circular
          onClick={handleClickOpen}
        >
          <MDTypography
            textTransform='uppercase'
            variant=' button'
            color='white'
          >
            Add staff
          </MDTypography>
        </MDButton>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant='gradient'
                bgColor='green'
                borderRadius='lg'
                coloredShadow='info'
              >
                <MDTypography variant='h4' textAlign='center' color='white'>
                  Staff table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <MaterialTable
                  columns={columns}
                  data={rows}
                  title=''
                  actions={[
                    (rowData) => ({
                      icon: 'edit',
                      tooltip: 'Edit staff',
                      onClick: async (event, rowData) => {
                        handleEdit(rowData.userId, rowData.userEmail, rowData.UserName)
                      },
                    }),

                    (rowData) => ({
                      icon: 'delete',
                      tooltip: 'Delete staff',
                      iconProps: { color: 'primary' },
                      onClick: async (event, rowData) => {
                        handleDelete(rowData.userId, rowData.UserName)
                      },
                    }),
                  ]}
                  options={{
                    headerStyle: {
                      backgroundColor: '#EFF4F2',
                      color: 'black',
                      whiteSpace: 'nowrap',
                    },
                    filtering: false,
                    search: false,
                    sorting: false,
                    emptyRowsWhenPaging: false,
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [10, 15, 20, 25, 30],
                    rowStyle: (rowData) => {
                      if (rowData.tableData.id % 2)
                        return {
                          backgroundColor: '#FAFAFA',
                        }
                    },
                  }}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add a staff</DialogTitle>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component='form' role='form'>
            <form autoComplete='off'>
              <MDBox mb={2}>
                <MDInput
                  type='email'
                  label='Email'
                  fullWidth
                  onChange={onEmailChange}
                />
                <MDBox mt={3} mb={1} textAlign='center'>
                  <MDTypography variant='button' color='error'>
                    {errors1}
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type='text'
                  label='Display name'
                  fullWidth
                  onChange={onDisplayNameChange}
                />
                <MDBox mt={3} mb={1} textAlign='center'>
                  <MDTypography
                    textAlign='center'
                    variant='button'
                    color='error'
                  >
                    {errors2}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </form>
            <MDBox mb={2}>
              <MDInput
                type='password'
                label='Password'
                fullWidth
                onChange={onPasswordChange}
              />
            </MDBox>
            <MDBox mt={3} mb={1} textAlign='center'>
              <MDTypography variant='button' color='error'>
                {errors}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>

        <DialogActions>
          <MDButton
            variant='text'
            color='error'
            onClick={handleClose}
            fullWidth
          >
            Cancel
          </MDButton>
          <MDButton
            variant='gradient'
            color='green'
            circular
            onClick={onsubmit}
            fullWidth
          >
            Save
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog open={openEdit} onClose={handleClose}>
              <DialogTitle>Edit staff</DialogTitle>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component='form' role='form'>
                  <MDBox mb={2}>
                    <MDInput
                      type='email'
                      label='Email'
                      value={email}
                      circular
                      fullWidth
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      value={displayName}
                      type='text'
                      label='Display name'
                      circular
                      onChange={(e) => setDisplayName(e.target.value)}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mt={3} mb={1} textAlign='center'>
                    <MDTypography variant='button' color='error'>
                      {errors}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
              <DialogActions>
                <MDButton
                  variant='text'
                  color='error'
                  onClick={handleClose}
                  fullWidth
                >
                  Cancel
                </MDButton>
                <MDButton
                  variant='gradient'
                  color='green'
                  circular
                  onClick={() => onsubmitEdit()}
                  fullWidth
                >
                  Confirm
                </MDButton>
              </DialogActions>
      </Dialog>
      <Dialog open={openDel} onClose={handleClose}>
              <DialogTitle>Delete staff</DialogTitle>
              <MDBox pt={2} py={-10} pb={3} px={3}>
                <MDTypography variant='button' color='black'>
                  Do you want to delete {editDisplayName}?
                </MDTypography>
              </MDBox>
              <DialogActions>
                <MDButton
                  variant='text'
                  color='error'
                  onClick={() => handleClose()}
                  fullWidth
                >
                  Cancel
                </MDButton>
                <MDButton
                  variant='gradient'
                  color='green'
                  circular
                  onClick={() => onsubmitDel()}
                  fullWidth
                >
                  Confirm
                </MDButton>
              </DialogActions>
            </Dialog>
    </DashboardLayout>
  )
}

export default Users
