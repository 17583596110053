import { useEffect, useState } from 'react'

import ComplexStatisticsCard from 'examples/Cards/StatisticsCards/ComplexStatisticsCard'
// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import Footer from 'examples/Footer'
// @mui material components
import Grid from '@mui/material/Grid'
// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import OrdersOverview from 'layouts/dashboard/components/OrdersOverview'
// Dashboard components
import Projects from 'layouts/dashboard/components/Projects'
import PieChart from 'examples/Charts/PieChart'
import moment from 'moment'
import ReportsLineChart from 'examples/Charts/LineCharts/ReportsLineChart'
// Data
import data from "layouts/dashboard/components/Projects/data"
import axios from 'axios'
import { array } from 'yup'
import { async } from '@firebase/util'
let classChart = []
const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};
function Dashboard() {
  const [TickersData, setTickersData] = useState([])
  const [pending, setPending] = useState(0)
  const [classes, setClasses] = useState(0)
  const [classesToday, setClassesToday] = useState([])

  const [bookings, setBookings] = useState(0)
  const [revenue, setRevenue] = useState(0)
  const [labelsBookings, setLabelsBookings] = useState([])
  const [dataBookings, setDataBookings] = useState([])
  const [classesName, setClassesName] = useState([])
  const [bookingsClassesName, setBookingsClassesName] = useState([])

  let className = []
  let c = []
  let countbookingsPerClass = []
  let countpending = 0
  let countbookings = 0 
  let countprice = 0 
  let countclasses = 0 
  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  let weeks = []
  let weeksData = []

 // let { sales, tasks } = reportsLineChartData({revenueData})
 const [revenueGraph, setRevenueGraph]=useState({labels:  weeks,
 datasets: { label: "Revenue en DH", data: weeksData },})
 const [bookingsPie, setBookingsPie]=useState({
  labels: ["aucune classe"],
  datasets: { label: "Bookings per class", data: [0] },
})

  const getClasses = async() => {
  
  await  axios
      .get(`${process.env.REACT_APP_API_DEV}/classes/by-gym`, {
        headers: {
          Authorization: `${localStorage.getItem('firebaseToken')}`,
        },
      })
      .then((res) => {
        const g = res.data
        let date = new Date()
        c = g
        for (let index = 0; index < g.length; index++) {
          
          if(date.toLocaleDateString(options).includes(new Date(g[index].startAt).toLocaleDateString(options)) && g[index].status != "blocked"){
            countclasses++
            setClasses(countclasses)
           
            if(classesToday.length < countclasses){
              classesToday.push(g[index]) 
            }
            
          }
          
       //   console.log(className, countbookingsPerClass)
        
        }
       
      })
  }
  const countBookingsPie = async()=>{
    
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/tickets/count-bookings`, {
      headers: {
        Authorization: `${localStorage.getItem('firebaseToken')}`,
      },
    })
    .then((res) => {
      const Tickets = res.data
      for (let index = 0; index < Tickets.length; index++) {
        labelsBookings.push(Tickets[index]._id.class)
        dataBookings.push(Tickets[index].totalClasses)
        console.log("tickets : ** ",Tickets[index].totalClasses)
      }

        if(labelsBookings.length > 0){
          
          setBookingsPie({ 
            labels: Object.values(labelsBookings),
            datasets: { label: "Bookings per class", data: Object.values(dataBookings) },
          })
        }
      

    })
  }
  const count = async() => {

    await  axios
      .get(`${process.env.REACT_APP_API_DEV}/classes/by-gym`, {
        headers: {
          Authorization: `${localStorage.getItem('firebaseToken')}`,
        },
      })
      .then((res) => {
        
        const g = res.data
        classChart = g
       console.log(g)
      })
    await axios
      .get(`${process.env.REACT_APP_API_DEV}/tickets/all-by-status-by-gym`, {
        headers: {
          Authorization: `${localStorage.getItem('firebaseToken')}`,
        },
      })
      .then(async(res) => {
        const Tickets = res.data
        console.log(classChart, className)
        let taille=classChart.length
        for (let index = 0; index < taille; index++) {
          let exist = false
          for (let i = 0; i < className.length; i++) {
            if (className[i] ===classChart[index].title ) {
              exist = true
            }
          }
          if (!exist) {
            className.push(classChart[index].title)
            countbookingsPerClass.push(0)
          }

          
         // setClassesName(className)
          //console.log(classChart)
          for (let i = 0; i < Tickets.length; i++) {
            if(Tickets[i].classId._id === classChart[index]._id && Tickets[i].status != "canceled" ){
              countbookingsPerClass[className.indexOf(classChart[index].title)]++
              setBookingsClassesName(countbookingsPerClass)
              
            }
          }
            if(className.length > 0){
              setBookingsPie({ 
                labels: Object.values(labelsBookings),
                datasets: { label: "Bookings per class", data: Object.values(dataBookings) },
              })
            }
           }
          
          
      })

     }
  const getDataTickets = async () => {
   await axios
   .get(`${process.env.REACT_APP_API_DEV}/tickets/all-by-status-by-gym`, {
     headers: {
       Authorization: `${localStorage.getItem('firebaseToken')}`,
     },
   })
   .then((res) => {
     const Tickets = res.data
     setTickersData(Tickets)

     for (let index = 0; index < Tickets.length; index++) {

      if (Tickets[index].availabilityStatus?.includes("pending") && !Tickets[index].status.includes("canceled")) {
        countpending++
        setPending(countpending)
      }
      if(!Tickets[index].status.includes("canceled")){
        countbookings++
        setBookings(countbookings)
        countprice+=Tickets[index].classId.price
        setRevenue(countprice)
        weeksData[moment(new Date(Tickets[index].createdAt)).week()]+=Tickets[index].classId.price
      }
     }

     setRevenueGraph({labels:  weeks,
      datasets: { label: "Revenue en DH", data:weeksData},})
   })
  }

  useEffect(async() => {
    if (localStorage.getItem("Token")) {
      const decodedJwt = parseJwt(localStorage.getItem("Token"));
      console.log(Date.now() )
      if (decodedJwt.exp * 1000 < Date.now()) {

      localStorage.removeItem('Token')
      }
    }
    const loggedInAdmin = localStorage.getItem("Token")
    if (!loggedInAdmin) {
      window.location.replace('/') 
    }
    getDataTickets()
    getClasses()
   // count()
    countBookingsPie()

    for (let index = 1; index < 53; index++) {
      weeks.push(index)
      weeksData.push(0)
    }


  }, [])
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color='primary'
                icon='weekend'
                title='Bookings'
                count={bookings}
                /**
                 *  percentage={{
                  color: 'success',
                  amount: '+55%',
                  label: 'than lask week',
                }}
                 */
               
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color='green'
                icon='store'
                title='Revenue'
                count={revenue + " DH"}
              /*  percentage={{
                  color: 'success',
                  amount: '+1%',
                  label: 'than yesterday',
                }}*/
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
              color='darkgreen'
                icon='class'
                title="Today's classes"
                count={classes}
             /*   percentage={{
                  color: 'success',
                  amount: '+3%',
                  label: 'than last month',
                }}*/
              />
            </MDBox>
          </Grid>
          
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color='warning'
                icon='book_online'
                title='Pending tickets'
                count={pending}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}  lg={8}>
              <MDBox mb={3}sx={{ height: "94%" }} >
                <ReportsLineChart

                  color='green'
                  title='Revenue per week'
                //  description={}
                //  date='updated 4 min ago'
                  chart={revenueGraph}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3} sx={{ height: "95%" }}>
                <PieChart
                  color='info'
                  title='Bookings per class'
              //    description='Last Campaign Performance'
              //    date='campaign sent 2 days ago'
                  chart={bookingsPie}
                />
              </MDBox>
            </Grid>
      {  /*    <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color='dark'
                  title='completed tasks'
                  description='Last Campaign Performance'
                  date='just updated'
                  chart={tasks}
                />
              </MDBox>
              </Grid>*/}
          </Grid>
        </MDBox>
        <MDBox >
          <Grid container  spacing={3}>
            <Grid item   xs={12} md={6} lg={8}>
              <Projects/>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  )
}

export default Dashboard