export const col = {
  border: '#e0e0e0 1px solid',
}
export const weekDays = {
  height: 100,
}

export const weekDayName = {
  fontSize: 12,
  lineHeight: '32px',
  textTransform: 'capitalize',
  color: '#757575',
  marginLeft: 10,
}

export const container = {
  paddingRight: '10em',
  paddingTop: '2em',
  paddingBottom: '2em',
}

export const weekDates = {
  fontSize: 40,
  lineHeight: '12px',
  color: '#70757a',
  marginLeft: 10,
}

export const slot = {
  height: 40,
  //  cursor: 'pointer',
}
export const timeCol = {
  fontSize: 10,
  color: '#212121',
  textAlign: 'right',
  padding: 6,
}

export const timeString = {
  position: 'absolute',
  right: 8,
  top: -8,
}

export const row = {
  position: 'relative',
}

export const toolbar = {
  paddingBottom: 10,
}

export const toolbarDate = {
  fontSize: 20,
  fontWeight: 400,
  opacity: 0.54,
  lineHeight: '30px',
  textAlign: 'right',
}

export const appTitle = {
  fontSize: 20,

  fontWeight: 400,
  lineHeight: '30px',
}

export const alignRight = {
  textAlign: 'right',
}

export const spacify = {
  marginRight: 5,
  backgroundColor: '#2699FB',
}
export const spacifyAndAlign = {
  marginRight: 5,
}
export const inputStyles = {
  marginTop: 25,
  marginBottom: 15,
}

export const weekButtons = {
  paddingRight: 0,
  paddingLeft: 14,
  textAlign: 'center',
}

export const lightHighlighter = {
  backgroundColor: '#EFF4F2',
}
export const viewClass = {
  top: '15%',
}

export const eventHighlighter = {
  position: 'absolute',
  border: '1px solid white',
  borderRadius: '5px',
  textAlign: 'center',
  color: 'white',
  fontWeight: 'bold',
  padding: '8px',
  fontSize: '12px',
  zIndex: 1,
  cursor: 'pointer',
}
