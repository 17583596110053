import { Button, Modal } from 'antd'
import { DatePicker, Input } from 'antd'
import React, { useEffect, useState } from 'react'

import AddEvent from './AddEvent'
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined'
import Card from '@mui/material/Card'
import ClassInfoCard from 'examples/Cards/InfoCards/ProfileInfoCard/classInfoCard'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import ProfileCard from 'examples/Cards/InfoCards/ProfileInfoCard/profileEdited'
import Select from 'react-select'
import axios from 'axios'
import { inputStyles } from '../styles'
import makeAnimated from 'react-select/animated'
import moment from 'moment'
import { viewClass } from '../styles'
const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};
const { RangePicker } = DatePicker
const animatedComponents = makeAnimated()
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    color: state.isSelected ? 'white' : '#1e3a4d',
    borderRadius: 32,
    fontSize: 24,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: 500,
  }),
}
function AddEventModal(props) {
  let visible = props.visible
  const [activities, setActivities] = useState([])
  const [openDel, setOpenDel] = useState(false)
  const getActiv = async () => {
    let a
    await axios
      .get(`${process.env.REACT_APP_API_DEV}/activities`)
      .then((res) => {
        a = res.data
      })
    setActivities((prevState) => {
      return a
    })
  }
  const onDelete = () => {
    setOpenDel(true)

  }
  const handleClose = () => {
    visible = false
    setOpenDel(false)
  }
  const onsubmitDel = async () => {
    axios.delete(`${process.env.REACT_APP_API_DEV}/classes/${props.event._id}`, {
      headers: {
        Authorization: `${localStorage.getItem('firebaseToken')}`,
      },
    })

    handleClose()
  }
  const onEdit = () => {
    console.log('edit button')
  }
  const onBlock = () => {
    console.log('block button')
  }
  const data =
    activities &&
    activities.map((act) => ({
      label: act.name,
      value: act._id,
    }))

  useEffect(() => {
    if (localStorage.getItem("Token")) {
      const decodedJwt = parseJwt(localStorage.getItem("Token"));
      console.log(Date.now() )
      if (decodedJwt.exp * 1000 < Date.now()) {

      localStorage.removeItem('Token')
      }
    }
    const loggedInAdmin = localStorage.getItem("Token")
    if (!loggedInAdmin) {
      window.location.replace('/') 
    }
    getActiv()
  }, [])
  return (
    <>
      {props.event && (
        <Modal
          style={viewClass}
          width={900}
          visible={visible}
          //onOk={this.handleOk}
          onCancel={props.onCancel}
          footer={[]}
        >
          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <MDBox p={2} px={3}>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={6} xl={1}></Grid>
                    <Grid item xs={12} md={6} xl={11}>
                      <ClassInfoCard
                        title='Class information'
                        description={props.event.description}
                        info={{
                          Title: `${props.event.title}`,
                          Address: `${props.event.addresss}`,
                          Price: `${props.event.price}`,
                          Stock: `${props.event.stock}`,
                          Remaining: `${props.event.remaining}`,
                          Arrival: `${props.event.arrival}`,
                          Calories: `${props.event.calories}`,
                          Preparation: `${props.event.preparation}`,
                          Difficulty: `${props.event.difficulty}`,
                          activity: `${props.event.category.name}`,
                        }}
                        social={[]}
                        action={{
                          route1: onEdit,
                          route2: onDelete,
                          route3: onBlock,
                          tooltip: '',
                        }}
                        shadow={false}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <Dialog open={openDel} onClose={handleClose}>
            <DialogTitle>Delete class</DialogTitle>
            <MDBox pt={2} py={-10} pb={3} px={3}>
              <MDTypography variant='button' color='black'>
                Do you want to delete {props.event.title} class?
              </MDTypography>
            </MDBox>
            <DialogActions>
              <MDButton
                variant='text'
                color='error'
                onClick={() => handleClose()}
                fullWidth
              >
                Cancel
              </MDButton>
              <MDButton
                variant='gradient'
                color='green'
                circular
                onClick={() => onsubmitDel()}
                fullWidth
              >
                Confirm
              </MDButton>
            </DialogActions>
          </Dialog>
        </Modal>
      )}
    </>
  )
}

export default AddEventModal
