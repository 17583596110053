/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eseslint-disable */

import 'react-toastify/dist/ReactToastify.css'

import { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CloseIcon from '@mui/icons-material/Close'
import Collapse from '@mui/material/Collapse'
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import Modal from '@mui/material/Modal'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import Tooltip from '@mui/material/Tooltip'
import { async } from '@firebase/util'
// import MDAvatar from "components/MDAvatar";
import axios from 'axios'
import pxToRem from 'assets/theme/functions/pxToRem'
import brand from 'assets/images/logo@2x.png'
import { toast } from 'react-toastify'

toast.configure()
const style = {
  bgcolor: 'background.paper',
}
export default function Data(UsersData) {
  return {
    columns: [
      {
        title: 'Display Name',
        field: 'UserName',
        cellStyle: {
          whiteSpace: 'nowrap',
          lineHeight: 1.6,
          fontSize: pxToRem(16),
          fontWeight: 300,
          fontFamily: ' "Arial", "Helvetica", sans-serif',
        },
        headerStyle: {
          backgroundColor: '#EFF4F2',
          color: 'black',
        },
      },
      {
        title: 'Email',
        field: 'userEmail',
        cellStyle: {
          whiteSpace: 'nowrap',
          lineHeight: 1.6,
          fontSize: pxToRem(16),
          fontWeight: 300,
          fontFamily: ' "Arial", "Helvetica", sans-serif',
        },
        headerStyle: {
          backgroundColor: '#EFF4F2',
          color: 'black',
        },
      },
      {
        title: 'User Id',
        field: 'userId',
        hidden: true,
        cellStyle: {
          whiteSpace: 'nowrap',
          lineHeight: 1.6,
          fontSize: pxToRem(16),
          fontWeight: 300,
          fontFamily: ' "Arial", "Helvetica", sans-serif',
        },
        headerStyle: {
          backgroundColor: '#EFF4F2',
          whiteSpace: 'nowrap',
          color: 'black',
        },
      },
    ],
    rows: UsersData.map((item) => {
      return {
        userId: item._id,
        UserName:item.displayName,
        userEmail: item.email,

      }
    }),
  }
}
