import { Button, Col, Row, Tooltip } from 'antd'
import {
  CalendarOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons'
import {
  alignRight,
  appTitle,
  spacify,
  spacifyAndAlign,
  toolbar,
  toolbarDate,
  weekButtons,
} from '../styles'

import MDTypography from 'components/MDTypography'
import React from 'react'
import moment from 'moment'

function WeekToolbar(props) {
  const formattedDate = moment(props.startDate).format('MMM YYYY')
  return (
    <Row type='flex' gutter={4} style={toolbar}>
      <Col span={6} offset={3} style={appTitle}></Col>
      <Col span={3} offset={8} style={alignRight}>
        <Tooltip placement='topLeft' title={moment().format('dddd, MMM D')}>
          <Button onClick={props.goToToday}>Today</Button>
        </Tooltip>
      </Col>

      <Col span={2} style={weekButtons}>
        <Button
          onClick={props.goToPreviousWeek}
          type='primary'
          icon={<LeftOutlined />}
          style={spacify}
        />
        <Button
          onClick={props.goToNextWeek}
          type='primary'
          icon={<RightOutlined />}
          style={spacify}
        />
      </Col>

      <Col span={2} style={toolbarDate}>
        {formattedDate}
      </Col>
    </Row>
  )
}

export default WeekToolbar
