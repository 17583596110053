// @mui material components
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import { Grid } from '@mui/material'
import Icon from '@mui/material/Icon'
// react-routers components
import IconButton from '@mui/material/IconButton'
import { Link } from 'react-router-dom'
// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
// prop-types is library for typechecking of props
import PropTypes from 'prop-types'
import Tooltip from '@mui/material/Tooltip'
// Material Dashboard 2 React base styles
import colors from 'assets/theme/base/colors'
import typography from 'assets/theme/base/typography'

function ProfileInfoCard({ title, description, info, social, action, shadow }) {
  const labels = []
  const values = []
  const { socialMediaColors } = colors
  const { size } = typography

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/))
      const newElement = el.replace(
        uppercaseLetter,
        ` ${uppercaseLetter.toLowerCase()}`
      )

      labels.push(newElement)
    } else {
      labels.push(el)
    }
  })

  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el))

  // Render the card info items
  const renderItems = labels.map((label, key) => (
    <MDBox key={label} display='flex' py={1} pr={2}>
      <MDTypography variant='h6' fontWeight='bold' textTransform='capitalize'>
        {label}:&nbsp;
      </MDTypography>
      <MDTypography variant='h6' fontWeight='regular'>
        &nbsp;{values[key]}
      </MDTypography>
    </MDBox>
  ))

  // Render the card social media icons
  const renderSocial = social.map(({ link, icon, color }) => (
    <MDBox
      key={color}
      component='a'
      href={link}
      target='_blank'
      rel='noreferrer'
      fontSize={size.lg}
      color={socialMediaColors[color].main}
      pr={1}
      pl={0.5}
      text
      lineHeight={1}
    >
      {icon}
    </MDBox>
  ))

  return (
    <Card sx={{ height: '100%', boxShadow: !shadow && 'none' }}>
      <MDBox
        display='flex'
        justify-content='space-around'
        alignItems='center'
        pt={2}
      >
        <MDTypography
          variant='h3'
          fontWeight='medium'
          //  textTransform='capitalize'
        >
          {title}
        </MDTypography>
        <IconButton
          size='medium'
          onClick={action.route}
          aria-label='edit'
          color='darkgreen'
        >
          <Tooltip title={action.tooltip} placement='bottom'>
            <ModeEditIcon />
          </Tooltip>
        </IconButton>
      </MDBox>
      <MDBox p={2} px={0}>
        <MDBox mb={2} lineHeight={1}>
          <MDTypography variant='h6' fontWeight='regular'>
            {description}
          </MDTypography>
        </MDBox>
        <MDBox opacity={0.3}>
          <Divider />
        </MDBox>
        <MDBox>
          {renderItems}
          <MDBox display='flex' py={1} pr={2}>
            <MDTypography
              variant='button'
              fontWeight='bold'
              textTransform='capitalize'
            >
              &nbsp;
            </MDTypography>
            {renderSocial}
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  )
}

// Setting default props for the ProfileInfoCard
ProfileInfoCard.defaultProps = {
  shadow: true,
}

// Typechecking props for the ProfileInfoCard
ProfileInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  social: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.shape({
    route: PropTypes.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
}

export default ProfileInfoCard
