import './styles.css'

import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const Navbar = ({days, activeDay, handleClick, showAll}) => {
  const [toggleMenu, setToggleMenu] = useState(false)
  const tout = {
    name:"tout",
    value: null
  }
  const daysArray = Object.values(days)

  return (
    <nav className='navbar'>

    <div className='navbar-links'>
    {showAll &&
     <div className='links'>
     <button onClick={()=>{handleClick(tout)}} className='buttonClass' style={{backgroundColor:"white", color:"black", borderBottomColor:"black", borderBottomWidth: activeDay == "tout" ? "2px":"0px"}} ><p class="button-cont">Tout</p></button>
     </div>
    }
      {
          daysArray && daysArray.map((c)=>(
              <div className='links'>

              <button onClick={()=>{handleClick(c)}} className='buttonClass'  id={c.name}  key={c.name} style={{backgroundColor:"white", color:"black", borderBottomColor:"black", borderBottomWidth: activeDay == c.name ? "2px":"0px"}}> <p class="button-cont">{c.name}</p></button>
      
              </div>
          ))
      }


    </div>

</nav>
  )
}

export default Navbar