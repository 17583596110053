import { Col, Row } from 'antd'
import { row, timeCol, timeString } from '../styles'

import React from 'react'
import TimeSlot from './TimeSlot'
import moment from 'moment'

function TimeSlotGroup(props) {
  const formattedTime = moment().set('hours', props.time).format('HH:00')

  return (
    <Row type='flex' key={props.time} style={row}>
      <Col style={timeCol} span={3}>
        <span style={timeString}>{formattedTime}</span>
      </Col>
      {props.weekDays.map((day) => (
        <TimeSlot
          key={day.dateStamp}
          dateStamp={day.dateStamp}
          time={props.time}
          openAddEventModal={props.openAddEventModal}
        />
      ))}
      {props.children}
    </Row>
  )
}

export default TimeSlotGroup
