/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

import Classes from 'layouts/classes'
// Material Dashboard 2 React layouts
import Dashboard from 'layouts/dashboard'
// @mui icons
import Icon from '@mui/material/Icon'
import Mygym from 'layouts/gyms'
import Profile from 'layouts/profile'
import Purchases from 'layouts/Purchases'
import SignIn from 'layouts/authentication/sign-in'
import SignUp from 'layouts/authentication/sign-up'
import Staffs from 'layouts/users'
import Tables from 'layouts/tables'
import Presences from 'layouts/presences'

const routes = [
  {
    type: 'collapse',
    name: 'Dashboard',
    key: 'dashboard',
    icon: <Icon fontSize='small'>dashboard</Icon>,
    route: '/dashboard',
    component: <Dashboard />,
  },
  {
    type: 'collapse',
    name: 'Présences',
    key: 'Presences',
    icon: <Icon fontSize='small'>how_to_reg_icon</Icon>,
    route: '/Presences',
    component: <Presences />,
  },
  {
    type: 'collapse',
    name: 'Tickets',
    key: 'Tickets',
    icon: <Icon fontSize='small'>table_view</Icon>,
    route: '/Tickets',
    component: <Tables />,
  },

  {
    type: 'collapse',
    name: 'Staffs',
    key: 'staffs',
    icon: <Icon fontSize='small'>person</Icon>,
    route: '/staffs',
    component: <Staffs />,
  },
  /**  {
    type: 'collapse',
    name: 'Purchases',
    key: 'Purchases',
    icon: <Icon fontSize='small'>format_textdirection_r_to_l</Icon>,
    route: '/Purchases',
    component: <Purchases />,
  }, */

  {
    type: 'collapse',
    name: 'Classes',
    key: 'classes',
    icon: <Icon fontSize='small'>class</Icon>,
    route: '/classes',
    component: <Classes />,
  },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  {
    // type: "collapse",
    name: 'Sign In',
    key: 'sign-in',
    icon: <Icon fontSize='small'>login</Icon>,
    route: '/authentication/sign-in',
    component: <SignIn />,
  },
  {
    type: 'collapse',
    name: 'My gym',
    key: 'my-gym',
    icon: <Icon fontSize='small'>fitness_center</Icon>,
    route: '/my-gym',
    component: <Mygym />,
  },
]

export default routes
