import React, { useEffect, useState } from 'react'
import { generateWeekView, generateWeekViewCoordinates } from '../../utils'

import { Checkbox } from 'antd'
import ClassInfoCard from 'examples/Cards/InfoCards/ProfileInfoCard/classInfoCard'
import { DatePicker } from 'antd'
import { Description } from '@mui/icons-material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MenuItem from '@mui/material/MenuItem'
import { Modal } from 'antd'
import Select from 'react-select'
import Tooltip from '@mui/material/Tooltip'
import axios from 'axios'
import { eventHighlighter } from '../styles'
import moment from 'moment'
import { toast } from 'react-toastify'
const style = {
  width: 300,
}
toast.configure()
function EventHighlighter(props) {
  const [showClassModal, setShowClassModal] = useState(false)
  const [openDel, setOpenDel] = useState(false)
  const [openEd, setOpenEd] = useState(false)
  const [openEd2, setOpenEd2] = useState(false)
  const [dayPass, setDayPass] = useState(false)
  const [autojoin, setAutojoin] = useState(false)
  const [errors, setErrors] = useState('')
  const [title, setTitle] = useState('')
  const [gender, setGender] = useState('')
  const [index, setIndex] = useState(0)
  const [age, setAge] = useState([])
  const [address, setAddress] = useState('')
  const [arrival, setArrival] = useState('')
  let time = moment(new Date(props.event.startAt)).hours()
  let start = props.event.startAt
  let end = props.event.endAt
  const [preparation, setPreparation] = useState('')
  const [description, setDescription] = useState('')
  const [price, setPrice] = useState(0)
  const [YFCount, setYFCount] = useState(0)
  const [difficulty, setDifficulty] = useState(0)
  const { RangePicker } = DatePicker
  const notifySuccess = () => {
    toast.success('Successfull!', { autoClose: 3000 })
  }
  const data = [
    { value: 'Female', label: 'Female' },
    { value: 'Male', label: 'Male' },
    { value: 'Mixed', label: 'Mixed' },
  ]

  const onDelete = () => {
    setOpenDel(true)
  //  console.log(props.event.age)
  //  console.log(props.event)
    setErrors('')
  }
  const handleClose = () => {

    setOpenDel(false)
    setOpenEd(false)
    setOpenEd2(false)
    setErrors('')
  }

  const onsubmitDel = async () => {
    props.onEventDelete(props.event._id)
    setOpenDel(false)
    setErrors('')
    closeModal()
  }
  const onEditSubmit2 = async () =>{
    setErrors('')
    if(!YFCount){
      setErrors("Please fill the information.")
    }else if(!Number.isInteger(Number(YFCount))){
      setErrors("Please enter a correct number.")
    }else{
      const user =JSON.parse(localStorage.getItem('user'))

      axios
      .put(`${process.env.REACT_APP_API_DEV}/classes/${props.event._id}/stock`, {
        classId: props.event._id,
    remainingTickets: YFCount,
    currentUserId: user._id,
      })
      .catch(function (error) {
        const notifyFailure = (msg) => {
          toast.error(msg, { autoClose: 3000 })
        }
        if (error.response) {
        //  console.log('****************response', error.response.data.message)
          if(error.response.data.message==="Number of yofitt tickets shouldn't be below greater than the maximum number of tickets"){

            setErrors("Number of yofitt tickets shouldn't be greater than the maximum number of tickets")
          }else if(error.response.data.message==="yfTicketCount and purchasedTicketsCount difference must be positive"){

            setErrors("Number of yofitt tickets should not be less than the number of tickets already purchased")
          }else{
            notifyFailure('Please try again')
            setOpenEd2(false)
          }
          
        } else if (error.request) {

          notifyFailure('Please try again')
          setOpenEd2(false)
        } else {

          notifyFailure('Please try again')
          setOpenEd2(false)
        }
      //  console.log(error.config)
      })
      .then(async (res) => {
        if (res.data) {
          props.onEventUpdateStock(props.event._id,YFCount,user._id)
          notifySuccess()
          setOpenEd2(false)
        }
      })
     
    
     setErrors('')
    
    }
 
  }
  const onEditSubmit = async () => {
    if (
      !title ||
      !preparation ||
      !arrival ||
      !address ||
      !price ||
      !difficulty ||
      !description
    ) {
      setErrors('Please fill all information.')
    } else {
      setErrors('')
      props.onEventUpdate(
        props.event._id,
        title,
        address,
        price,
        difficulty,
        preparation,
        arrival,
        moment(start).toDate(),
        moment(end).toDate(),
        props.event.stock,
        props.event.area,
        props.event.calories,
        props.event.activity,
        age,
        description,
        gender,
        autojoin,
        dayPass,

      )
      setOpenEd(false)
      closeModal()
    }
  }
  const onOk = (dates) => {
    start = dates[0].format()
    end = dates[1].format()
  }
  const onEdit = () => {
  //  console.log('edit button')
    setErrors('')
    setTitle(props.event.title)
    setPreparation(props.event.preparation)
    setAddress(props.event.addresss)
    setPrice(props.event.price)

    setArrival(props.event.arrival)
    setDescription(props.event.description)
    setDifficulty(props.event.difficulty)
    setGender(props.event.allowedGender)
    setDayPass(props.event.isDayPass)
    setAutojoin(props.event.autoJoining)
    setAge(props.event.age)
    if (gender.includes('female')) {
      setIndex(0)
    } else if (gender.includes('mixed')) {
      setIndex(2)
    } else {
      setIndex(1)
    }

    closeModal()
    setOpenEd(true)
  }
  const onEdit2 = () => {
    setErrors('')
    setYFCount(props.event.yfTicketsCount)
    closeModal()
    setOpenEd2(true)
  }
  
  const onUnBlock = async () => {
    props.onUnblockEvent(props.event._id)
    handleClose()
  }
  const onBlock = async () => {
    props.onNewEvent(props.event._id)
    handleClose()
  }
  const onChange = (dates) => {

    start = dates[0].format()
    end = dates[1].format()
  //  console.log('Selected Time: ', moment(start).toDate())
  //  console.log('Formatted Selected Time: ', moment(end).toDate())
  }
  const openClassModal = () => {
  
    setShowClassModal(true)
  }
  const closeModal = () => {
    setShowClassModal(false)
  }

  return (
    <React.Fragment>
      <Modal
        width={900}
        visible={showClassModal}
        //onOk={this.handleOk}
        onCancel={closeModal}
        footer={[]}
      >
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <MDBox p={2} px={3}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6} xl={1}></Grid>
                  <Grid item xs={12} md={6} xl={11}>
                    <ClassInfoCard
                      title='Class information'
                      status={props.event.status}
                      description={props.event.description}
                      info={{
                        Title: `${props.event.title}`,
                        Address: `${props.event.addresss}`,
                        Price: `${props.event.price}`,
                        Start:`${moment(new Date(props.event.startAt)).format('YYYY-MM-DD')} At ${moment(new Date(props.event.startAt).getTime()).format('HH:mm')}` ,
                        End:`${moment(new Date(props.event.endAt)).format('YYYY-MM-DD')} At ${moment(new Date(props.event.endAt).getTime()).format('HH:mm')}`,
                        Gender: `${props.event.allowedGender}`,
                        Age: `${props.event.age}`,
                        Auto_joining: `${props.event.autoJoining}`,
                        Day_pass: `${props.event.isDayPass}`,
                        Stock: `${props.event.stock}`,
                        Remaining: `${props.event.remaining}`,
                        YF_tickets_count: `${props.event.yfTicketsCount}`,
                        Arrival: `${props.event.arrival}`,
                        Calories: `${props.event.calories}`,
                        Preparation: `${props.event.preparation}`,
                        Difficulty: `${props.event.difficulty}`,
                        activity: `${props.event.category.name}`,
                      }}
                      social={[]}
                      action1={{
                        route: onEdit,
                        tooltip: '',
                      }}
                      action5={{
                        route: onEdit2,
                        tooltip: '',
                      }}
                      action2={{
                        route: onDelete,
                        tooltip: '',
                      }}
                      action3={{
                        route: onBlock,
                        tooltip: '',
                      }}
                      action4={{
                        route: onUnBlock,
                        tooltip: '',
                      }}
                      shadow={false}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </Modal>

      <Tooltip title={props.event.title} placement='bottom'>
        <div
          onClick={() => openClassModal()}
          style={{
            ...generateWeekViewCoordinates(
              props.event,
              props.startDate,
              props.long,
              props.index,
              props.events
            ),
            ...eventHighlighter,
          }}
        >
          {props.event.title} <br />
        </div>
      </Tooltip>

      <Dialog open={openDel} onClose={handleClose}>
        <DialogTitle>Delete class</DialogTitle>
        <MDBox pt={2} py={-10} pb={3} px={3}>
          <MDTypography variant='button' color='black'>
            Do you want to delete {props.event.title} class?
          </MDTypography>
        </MDBox>
        <DialogActions>
          <MDButton
            variant='text'
            color='error'
            onClick={() => handleClose()}
            fullWidth
          >
            Cancel
          </MDButton>
          <MDButton
            variant='gradient'
            color='green'
            circular
            onClick={() => onsubmitDel()}
            fullWidth
          >
            Confirm
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog open={openEd} onClose={handleClose}>
        <DialogTitle>Edit class</DialogTitle>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component='form' role='form'>
            <MDBox mb={2}>
              <MDInput
                type='text'
                label='Title'
                value={title}
                fullWidth
                onChange={(e) => setTitle(e.target.value)}
              />
            </MDBox>

            <MDBox mb={2}>
              <MDInput
                value={address}
                type='text'
                label='Address'
                onChange={(e) => setAddress(e.target.value)}
                fullWidth
                multiline
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                value={price}
                type='number'
                label='Price'
                onChange={(e) => setPrice(e.target.value)}
                fullWidth
                multiline
              />
            </MDBox> 

            <MDBox mb={2}>
              <MDInput
                value={arrival}
                type='text'
                label='Arrival'
                onChange={(e) => setArrival(e.target.value)}
                fullWidth
                multiline
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                value={description}
                type='text'
                label='Description'
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                multiline
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                value={address}
                type='text'
                label='Preparation'
                onChange={(e) => setAddress(e.target.value)}
                fullWidth
                multiline
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                value={difficulty}
                type='number'
                label='Difficulty'
                onChange={(e) => setDifficulty(e.target.value)}
                fullWidth
                multiline
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                value={age}
                type='string'
                label='age'
                onChange={(e) => setAge(e.target.value)}
                fullWidth
                multiline
              />
            </MDBox>
            <MDBox mb={2}>
              <Select
                closeMenuOnSelect={true}
                defaultValue={data[index]}
                autoFocus
                placeholder='Select gender'
                options={data}
                onChange={(e) => setGender(e.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <Checkbox
                checked={dayPass}
                onChange={(e) => setDayPass(e.target.checked)}
              >
                Day pass
              </Checkbox>
              <Checkbox
                checked={autojoin}
                onChange={(e) => setAutojoin(e.target.checked)}
              >
                Auto joining
              </Checkbox>
            </MDBox>

            <RangePicker
              style={{ width: '100%' }}
              defaultValue={[moment(start), moment(end)]}
              onChange={onChange}
              showTime={{
                format: 'HH:mm',
                hourStep: 1,
                minuteStep: 30,
                //  defaultValue: [moment(start), moment(end)],
              }}
              format='MMM Do, YYYY hh:mm a'
              onOk={onOk}
            />
            <MDBox mt={3} mb={1} textAlign='center'>
              <MDTypography variant='button' color='error'>
                {errors}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        <DialogActions>
          <MDButton
            variant='text'
            color='error'
            onClick={handleClose}
            fullWidth
          >
            Cancel
          </MDButton>
          <MDButton
            variant='gradient'
            color='green'
            circular
            onClick={() => onEditSubmit()}
            fullWidth
          >
            Confirm
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog    open={openEd2} onClose={handleClose}>
        <DialogTitle>Edit YF tickets count</DialogTitle>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox sx={{ ...style }} component='form' role='form'>
            <MDBox mb={2}>
              <MDInput
                value={YFCount}
                type="number"
              
                label='YF tickets count'
                onChange={(e) => setYFCount(e.target.value)}
                fullWidth
                multiline
              />
            </MDBox>
            <MDBox mt={3} mb={1} textAlign='center'>
              <MDTypography variant='button' color='error'>
                {errors}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        <DialogActions>
          <MDButton
            variant='text'
            color='error'
            onClick={handleClose}
            fullWidth
          >
            Cancel
          </MDButton>
          <MDButton
            variant='gradient'
            color='green'
            circular
            onClick={() => onEditSubmit2()}
            fullWidth
          >
            Confirm
          </MDButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default EventHighlighter
