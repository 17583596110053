/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container'
import Icon from '@mui/material/Icon'
import Link from '@mui/material/Link'
// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'
// Material Dashboard 2 React base styles
import typography from 'assets/theme/base/typography'

function Footer({ light }) {
  const { size } = typography

  return (
    <MDBox position='absolute' width='100%' bottom={0} py={4}>
      <Container>
        <MDBox
          width='100%'
          display='flex'
          flexDirection={{ xs: 'column', lg: 'row' }}
          justifyContent='space-between'
          alignItems='center'
          px={1.5}
        >
          <MDBox
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexWrap='wrap'
            color={light ? 'black' : 'text'}
            fontSize={size.sm}
          >
            &copy; {new Date().getFullYear()},
            <Link href='https://yofitt.com' target='_blank'>
              <MDTypography
                variant='button'
                fontWeight='medium'
                color={light ? 'black' : 'text'}
              >
                &nbsp;yofitt&nbsp;
              </MDTypography>
            </Link>
          </MDBox>
        </MDBox>
      </Container>
    </MDBox>
  )
}

// Setting default props for the Footer
Footer.defaultProps = {
  light: false,
}

// Typechecking props for the Footer
Footer.propTypes = {
  light: PropTypes.bool,
}

export default Footer
