import React, { Component } from 'react'

import CalendarEventHandler from './calendarEventHandler'
import WeekView from './weekView'
import axios from 'axios'
import moment from 'moment'
import { toast } from 'react-toastify'

toast.configure()

class GoogleCalendar extends Component {
  constructor(props) {
    super(props)
    axios.defaults.headers.common['Authorization'] = `${localStorage.getItem(
      'firebaseToken'
    )}`
    this.state = {
      events: {},
      message:"",
    }

    this.getEvents()
  }

  notifySuccess = () => {
    toast.success('Successfull!', { autoClose: 3000 })
  }
  notifyFailure = () => {
    toast.error('Please retry again', { autoClose: 3000 })
  }
  getEvents = () => {
    axios
      .get(`${process.env.REACT_APP_API_DEV}/classes/by-gym`)
      .then((res) => {
        const g = res.data
       // console.log('test*****', g)
        for (let index = 0; index < g.length; index++) {
       //   console.log(g[index]._id)
          this.setState((previousSate) => ({
            events: CalendarEventHandler.add(previousSate.events, g[index]),
          }))
        }
      //  console.log(this.state.events)
        //  console.log(new Date(g.startAt).getTime())
        //  console.log(moment(new Date(g.startAt)).week())
        //  console.log(moment(new Date(g.startAt).getTime()).format('hh:mm a'))
        //  console.log(moment(new Date(g.endAt).getTime()).format('hh:mm a'))
      })
  }

  /**
   * Add new event in the event list in the state
   * @param {String} eventId
   * {
   *  start: {timeStamp} - Time stamp for the start of the event,
   *  title: {string} - Title fo the new event,
   *  end: {timeStamp} - Time stamp for the end of the event,
   * }
   */
  blocEvent = (eventId) => {
    axios
      .put(`${process.env.REACT_APP_API_DEV}/classes/${eventId}/block/by-gym`)
      .catch(function (error) {
        const notifyFailure = () => {
          toast.error('Please retry again', { autoClose: 3000 })
        }
        if (error.response) {
          console.log(error.response)
          notifyFailure()
        } else if (error.request) {
          console.log('request')
          notifyFailure()
        } else {
          console.log('autre')
          notifyFailure()
        }
        console.log(error.config)
      })
      .then(async (res) => {
        if (res.data) {
          this.setState((previousSate) => ({
            events: {},
          }))

          await this.getEvents()
          this.notifySuccess()
        }
      })
  }

  unblocEvent = (eventId) => {
    axios
      .put(`${process.env.REACT_APP_API_DEV}/classes/by-gym/${eventId}/unblock`)
      .catch(function (error) {
        const notifyFailure = () => {
          toast.error('Please retry again', { autoClose: 3000 })
        }
        if (error.response) {
          console.log('response')
          notifyFailure('Tickets were already sold for this class.')
        } else if (error.request) {
          console.log('request')
          notifyFailure('Please retry again.')
        } else {
          console.log('autre')
          notifyFailure('Please retry again.')
        }
        console.log(error.config)
      })
      .then(async (res) => {
        if (res.data) {
          this.setState((previousSate) => ({
            events: {},
          }))

          await this.getEvents()
          this.notifySuccess()
        }
      })
  }
  /**
   * Updates an already existing event in the state event list
   * @param {string} event eventID id of the event
   * @param {Object} updatedEvent updated details of the event
   * {
   *  start: {timeStamp} - Time stamp for the start of the event,
   *  title: {string} - Title fo the new event,
   *  end: {timeStamp} - Time stamp for the end of the event,
   * }
   */
  updateEvent = (
    eventId,
    title,
    address,
    price,
    difficulty,
    preparation,
    arrival,
    startAt,
    endAt,
    stock,
    area,
    calories,
    activity,
    age,
    description,
    allowedGender,
    autoJoining,
    isDayPass,

  ) => {
    axios
      .put(`${process.env.REACT_APP_API_DEV}/classes/${eventId}`, {
        title: title,
        addresss: address,
        price: price,
        difficulty: difficulty,
        preparation: preparation,
        arrival: arrival,
        startAt: startAt,
        endAt: endAt,
        stock: stock,
        area: area,
        Calories: calories,
        activity: activity,
        description: description,
        age: age,
        allowedGender: allowedGender,
        autoJoining: autoJoining,
        isDayPass: isDayPass,
      })
      .catch(function (error) {
        const notifyFailure = (msg) => {
          toast.error(msg, { autoClose: 3000 })
        }
        if (error.response) {
        //  console.log('****************response', error.response.data.message)
          notifyFailure(error.response.data.message)
        } else if (error.request) {

          notifyFailure('Please try again')
        } else {

          notifyFailure('Please try again')
        }
        console.log(error.config)
      })
      .then(async (res) => {
        if (res.data) {
          this.setState(() => ({
            events: {},
          }))

          await this.getEvents()
          this.notifySuccess()
        }
      })
  }
  updateStock = (
    classId,
    remainingTickets,
    currentUserId,
  ) => {
    this.setState(() => ({
      events: {},
    }))

     this.getEvents()
  }
  /**
   * Deletes an event from the event list in the state
   * @param {String} eventId - Id of the event
   */
  deleteEvent = async (eventId) => {
    await axios
      .delete(`${process.env.REACT_APP_API_DEV}/classes/${eventId}`)
      .catch(function (error) {
        const notifyFailure = (msg) => {
          toast.error(msg, { autoClose: 3000 })
        }
        if (error.response) {

          notifyFailure(error.response.data.message)
        } else if (error.request) {

          notifyFailure('Please retry again.')
        } else {

          notifyFailure('Please retry again.')
        }
        console.log(error.config)
      })
      .then(async (res) => {
        if (res.data) {
          this.setState((previousSate) => ({
            events: {},
          }))

          await this.getEvents()
          this.notifySuccess()
        }
      })
  }

  render() {
    const { events, message } = this.state
    return (
      <WeekView
        events={events}
        onNewEvent={this.blocEvent}
        onUnblockEvent={this.unblocEvent}
        onEventUpdate={this.updateEvent}
        onEventDelete={this.deleteEvent}
        onEventUpdateStock = {this.updateStock}
        message={message}
      />
    )
  }
}

export default GoogleCalendar
