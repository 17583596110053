import axios from 'axios';
import { toast } from 'react-toastify'

toast.configure()

async function markAsNoShow(ticket, handleClose, getAllData){
  const notifySuccess = () => {
    toast.success('Successful!', { autoClose: 3000 })
  }
  const notifyFailure = (msg) => {
    toast.error(msg, { autoClose: 3000 })
  }

    await axios
      .patch(
        `${process.env.REACT_APP_API_DEV}/tickets/no-show/${ticket.id}`,
        null, 
        {
          headers: {
            Authorization: `${localStorage.getItem('firebaseToken')}`,
          },
        }
      )
      .catch(function (error) {
        if (error.response) {
      //    console.log('response', error.response.data.message)
          notifyFailure(error.response.data.message)
        } else if (error.request) {
     //     console.log('request')
          notifyFailure("please retry")
          
        } else {
     //     console.log('autre')
          notifyFailure("please retry")
          
        }
     //   console.log(error.config)
      })
      .then((res) => res.data)
      .then(handleClose)
      .then(getAllData)
      .then(notifySuccess)

  }
  export default markAsNoShow;