/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from 'react'

import Card from '@mui/material/Card'
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import DataTable from 'examples/Tables/DataTable'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import EXtractTickets from './modals/extractTickets'
import Footer from 'examples/Footer'
// @mui material components
import Grid from '@mui/material/Grid'
// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MaterialTable from 'material-table'
import Navbar from "examples/Navbars/TableNavbar";
import FilterTickets from './modals/filterTickets'
import moment from 'moment'
// Data
import authorsTableData from 'layouts/tables/data/authorsTableData'
import axios from 'axios'
import { toast } from 'react-toastify'
import "./styles.css"
toast.configure()
// import projectsTableData from "layouts/tables/data/projectsTableData";
const pf = process.env.API_DEV
const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};
function Tables() {
 
  const [disable, setDisable] = useState(false)
  const [openDel, setOpenDel] = useState(false)
  const [open, setOpen] = useState(false)
  const [openUse, setOpenUse] = useState(false)
  const [reason, setReason] = useState(false)
  const [errors, setErrors] = useState('')
  const [TickersData, setTickersData] = useState([])
  const [openExtractTicket, setOpenExtractTicket] = useState(false)
  const [data, setData] = useState({})
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [classChange, setClassChange] = useState("buttonn-focus")
  const [all, setAll] = useState(true)
  const [acceptTab, setAcceptTab] = useState(false)
  const [Gym, setGym] = useState({})
  const [selectedDayFilter, setSelectedDayFilter] = useState(null)
  const [filterByDay, setFilterByDay] = useState(false)
  const [sDate, setSDate] = useState(null)
  const [eDate, setEDate] = useState(null)
  const [resAttente, setResAttente] = useState(0)
  const [resAttenteToday, setResAttenteToday] = useState(0)
  const [ openFilterModel, setOpenFilterModel] = useState(false)
  const [activeDay, setActiveDay] = useState("tout")
  const [color, setColor] = useState('dark');

  let columns = (authorsTableData(TickersData).columns)
  let rows = (authorsTableData(TickersData).rows)
  const smalldayMap =([
    ["Dim"],
    ["Lun"],
    ["Mar"],
    ["Mer"],
    ["Jeu"],
    ["Ven"],
    ["Sam"]
  ]);
  const [days, setDays] = useState([
    {
      name: "Hier",
      value: new Date().setDate(new Date().getDate() - 1)
    }
    ,{
    name: "Aujourd'hui",
    value: new Date()
  }]);

  function getsmallFormatedDate(date) {
    if (date instanceof Date === false) {
      date = new Date(date);
    }
    let returnValue = `${smalldayMap[date.getDay()]} ${date.getDate()}`;
    return returnValue;
  }

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return {
      name: getsmallFormatedDate(result),
      value: result
    };
  }
  const filter = () =>{
    if (fromDate && toDate) {
      const debutDate = new Date(fromDate)
      const yeardebut = debutDate.getFullYear();
      const monthdebut = String(debutDate.getMonth() + 1).padStart(2, '0'); 
      const daydebut = String(debutDate.getDate()).padStart(2, '0');
    
      const finDate = new Date(toDate)
      const yearfin = finDate.getFullYear();
      const monthfin = String(finDate.getMonth() + 1).padStart(2, '0'); 
      const daydefin = String(finDate.getDate()).padStart(2, '0');
    
      setSDate(`${yeardebut}-${monthdebut}-${daydebut}`)
      setEDate(`${yearfin}-${monthfin}-${daydefin}`)
    }else{
      setSDate(null)
      setEDate(null)
    }
  
    setOpenFilterModel(true)
  }
  const handleClickDay = (date) =>{
    setActiveDay(date.name)
    setFromDate(null)
    setToDate(null)
    setSDate(null)
    setEDate(null)
    if (date.value) {
      setSelectedDayFilter(moment(new Date(date.value)).format('YYYY-MM-DD'))
      setFilterByDay(true)
    }else{
      setSelectedDayFilter(null)
      setFilterByDay(false)
    }
    
  }
  const notifySuccess = () => {
    toast.success('Successfull', { autoClose: 3000 })
  }
  const notifyFailure = (msg) => {
    toast.error(msg, { autoClose: 3000 })
  }
  const handleAllClick = () =>{
    setClassChange("buttonn-focus")
    setAcceptTab(false)
    setAll(true)
  }
  const handleAcceptClick = () =>{
    setClassChange("buttonn")
    setAll(false)
    setAcceptTab(true)
    
  }
  function rowsF() {
   
    const rowsToShow = acceptTab ? rows.filter(row => row?.status?.includes("available") && row?.availability?.includes("pending")) : rows
    if (filterByDay) {
      return rowsToShow.filter(row => row?.classDate?.includes(selectedDayFilter));
    }else  if (fromDate && toDate) {
      return rowsToShow.filter(row =>  (new Date(row.ticketDoc?.class?.startAt ? row.ticketDoc?.class?.startAt : row.ticketDoc?.classId?.startAt) >= new Date(fromDate) && new Date(row.ticketDoc?.class?.startAt ? row.ticketDoc?.class?.startAt : row.ticketDoc?.classId?.startAt) <= new Date(toDate)));
    }
    else {
      return rowsToShow
    }
   
    
  }
  const handleClose = () => {
    setOpenDel(false)
    setOpenUse(false)
    setOpen(false)
    setErrors('')
  }
  const getGym = async () => {
    const id = JSON.parse(localStorage.getItem('user')).gymId

    axios
      .get(`${process.env.REACT_APP_API_DEV}/gyms/${id}`)
      .then((res) => {
        const g = res.data
        setGym((prevState) => {
          return g
        })
      })
    //dispatch(GetGym(id))
  }
  const onsubmitDel = async () => {

    if (!reason) {
      setErrors('Please enter the refuse reason')
    } else {
      await axios
        .put(`${process.env.REACT_APP_API_DEV}/tickets/refuse/${data.id}`, {
          userId: data.userId,
          refuseReason: reason,
        })
        .catch(function (error) {
          if (error.response) {
            console.log("response error")
            notifyFailure(error.response.data.message)
            setOpenDel(false)
          } else if (error.request) {
            notifyFailure('Please retry again')
            setOpenDel(false)
          } else {
            notifyFailure('Please retry again')
            setOpenDel(false)
          }
          console.log(error.config)
        })
        .then(setOpenDel(false))
        .then((res) => {
          if (res.data) {
            console.log("***********", res)
            notifySuccess()
          }})
        .then(getData)
        .setErrors('')
    }
  }
  const onsubmit = async () => {
 //   console.log(data)
      await axios
        .put(`${process.env.REACT_APP_API_DEV}/tickets/accepte/${data.id}`, {
          userId: data.userId,
        })
        .catch(function (error) {
          if (error.response) {
            console.log("response error")
            notifyFailure(error.response.data.message)
          } else if (error.request) {
            notifyFailure('Please retry again')
            setOpenDel(false)
          } else {
            notifyFailure('Please retry again')
            setOpenDel(false)
          }
          console.log(error.config)
        })
        .then(setOpenDel(false))
        .then((res) => {
          if (res.data) {
            console.log("***********", res)
            notifySuccess()
          }})
        .then(getData)
        .setErrors('')
    
  }
  const onsubmitUse = async () =>{
    await axios
    .patch(`${process.env.REACT_APP_API_DEV}/tickets/useTicket`, {
      id: data.id,
      userId: data.userId,
    },
    {
      headers: {
        Authorization: `${localStorage.getItem('firebaseToken')}`,
      },
    })
    .catch(function (error) {
      if (error.response) {
        console.log("response error")
        notifyFailure(error.response.data.message)
        setOpenDel(false)
      } else if (error.request) {
        notifyFailure('Please retry again')
        setOpenDel(false)
      } else {
        notifyFailure('Please retry again')
        setOpenDel(false)
      }
      console.log(error.config)
    })
    .then(setOpenUse(false))
    .then((res) => {
      if (res.data) {

        notifySuccess()
      }})
    .then(getData)
    .setErrors('')
  }

  const handleValidate = async (rowData) => {
    setOpen(true)
    setData(rowData)
  }
  const handleRefuse = async (rowData) => {
    setOpenDel(true)
    setData(rowData)
  }
  const handleUseTicket = async (rowData) =>{
    setOpenUse(true)
    setData(rowData)
  }
  const getData = async () => {
    setOpenDel(false)
    setOpen(false)
    setData(
      axios
        .get(`${process.env.REACT_APP_API_DEV}/tickets/all-by-status-by-gym`, {
          headers: {
            Authorization: `${localStorage.getItem('firebaseToken')}`,
          },
        })
        .then((res) => {
          const Tickets = res.data
          setTickersData(Tickets)
          const earlyToday = new Date();
          const nightToday = new Date();
          earlyToday.setHours(0, 0, 0, 0)
          nightToday.setHours(23, 59, 59, 999)
          const resWaiting = res?.data?.filter(row => row?.status?.includes("available") && row?.availabilityStatus?.includes("pending"))
          console.log(resWaiting[0].classId?.startAt, resWaiting)
          const resWaitingToday = resWaiting?.filter(row => new Date(row?.classId?.startAt) >= earlyToday && new Date(row?.classId?.startAt) <= nightToday)
          setResAttente(resWaiting?.length > 0 ? resWaiting?.length : 0)
          setResAttenteToday(resWaitingToday?.length)
        })
    )
    columns = (authorsTableData(TickersData).columns)
    rows = (authorsTableData(TickersData).rows)
  }
  const disableUseButton = (date)=>{


  let modifiedDate = new Date(date);
  modifiedDate.setTime(modifiedDate.getTime() - 60 * 60 * 1000); 

  if (modifiedDate > new Date()) {
    return true;
  }
    return false
  }
  useEffect(() => {
    if (localStorage.getItem("Token")) {
      const decodedJwt = parseJwt(localStorage.getItem("Token"));

      if (decodedJwt.exp * 1000 < Date.now()) {

      localStorage.removeItem('Token')
      }
    }
    const loggedInAdmin = localStorage.getItem("Token")
    if (!loggedInAdmin) {
      window.location.replace('/') 
    }
    axios.defaults.headers.common['Authorization'] = `${localStorage.getItem(
      'firebaseToken'
    )}`
    getData()
    getGym()


    
  }, [])
  useEffect(() => {

    if (days.length <= 15) {

    for (let index = 1; index < 15; index++) {
      
      let date = new Date();
      days.push(addDays(date, index));

    }

    }
  }, [days])
  
  useEffect(() => {
    if (resAttenteToday > 0) {
      const intervalId = setInterval(() => {
        setColor(prevColor => (prevColor === 'error' ? 'dark' : 'error'));
      }, 1000);
  
      return () => clearInterval(intervalId);
    }else{
      setColor('dark')
    }
    
  }, [resAttenteToday]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
           
      <Grid container spacing={3}>

          <Grid item xs={9} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="warning"
                icon="pending_icon"
                title="Réservations en attente d'acceptation"
                count={resAttente}

              />
            </MDBox>
          </Grid>
          <Grid item xs={9} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color={color}
                icon="warning_icon"
                title="Réservations d'aujourd'hui en attente"
                count={resAttenteToday}

              />
            </MDBox>
          </Grid>
        </Grid>
      <MDBox textAlign='right'>
          <span style={{marginRight: 10}}>
            <MDButton
              variant='gradient'
              size='large'
              color='warning'
              circular
              onClick={()=>filter()}
            >
              <MDTypography
                textTransform='uppercase'
                variant=' button'
                color='white'
              >
                Filtrer
              </MDTypography>
            </MDButton>
          </span>
          <MDButton
          marginRight={2}
          variant='gradient'
          size='large'
          color='darkgreen'
          circular
          onClick={()=>setOpenExtractTicket(true)}
        >
          <MDTypography
            textTransform='uppercase'
            variant=' button'
            color='white'
          >
            Extraire
          </MDTypography>
        </MDButton>
        </MDBox>
      <MDBox textAlign='right'>

    
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>

              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant='gradient'
                bgColor='green'
                borderRadius='lg'
                coloredShadow='info'
              >
                <MDTypography variant='h4' textAlign='center' color='white'>
                  Tickets
                </MDTypography>
              </MDBox>
              <div class="divv">
                <button class={classChange} key={1} onClick={handleAllClick}>Tout</button>
                <button class="buttonn" id="b2" key={2} onClick={handleAcceptClick}>Accepter les réservations</button>      
              </div>
              <Navbar days={days} activeDay={activeDay} handleClick={handleClickDay} showAll={true} /> 
              <MDBox pt={3}>
                {/* <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                  canSearch
                /> */}

                <MaterialTable
                  columns={columns}
                  data={rowsF()}
                  title=' '
                  actions={[
                    (rowData) => ({
                      icon: 'done',
                      tooltip: 'Validate ticket',
                      disabled:rowData.status == 'canceled' || rowData.availability == 'accepted' || rowData.availability == 'refused',
                      onClick: async (event, rowData) => {
                        handleValidate(rowData)
                      },
                    }),

                    (rowData) => ({
                      icon: 'cancel',
                      tooltip: 'Refuse ticket',
                      disabled: rowData.status == 'canceled' || rowData.availability == 'accepted' || rowData.availability == 'refused',
                      onClick: async (event, rowData) => {
                        handleRefuse(rowData)
                      },
                    }),

                  /**
                   *   (rowData) => ({
                      icon: 'playlist_add_check',
                      tooltip: 'Mark as used',
                      disabled:rowData.status == 'used' || rowData.status == 'canceled' || Gym.canMarkAsUsed == false || Gym.canMarkAsUsed == null || disableUseButton(rowData.classStartAt),
                      onClick: async (event, rowData) => {
                        handleUseTicket(rowData)
                      },
                    }),
                   */
                  ]}
                  options={{
                    headerStyle: {
                      backgroundColor: '#EFF4F2',
                      color: 'black',
                      whiteSpace: 'nowrap',
                   //   marginTop: -5
                    },
                    filtering: true,
                    search: false,
                    sorting: false,
                    tableLayout: 'auto',
                    emptyRowsWhenPaging: false,
                    pageSize: 20,
                  //  actionsColumnIndex: -1,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [10, 15, 20, 25, 30],
                    rowStyle: (rowData) => {
                      if (rowData.tableData.id % 2)
                        return {
                          backgroundColor: '#FAFAFA',
                        }
                    },
                  }}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Dialog open={openDel} onClose={handleClose}>
        <DialogTitle>Refuse ticket</DialogTitle>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component='form' role='form'>
            <MDBox mb={2}>
              <MDInput
                type='text'
                label='Refuse reason'
                fullWidth
                onChange={(e) => setReason(e.target.value)}
              />
              <MDBox mt={3} mb={1} textAlign='center'>
                <MDTypography variant='button' color='error'>
                  {errors}
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
        <DialogActions>
          <MDButton
            variant='text'
            color='error'
            onClick={() => handleClose()}
            fullWidth
          >
            Cancel
          </MDButton>
          <MDButton
            variant='gradient'
            color='green'
            circular
            onClick={() => onsubmitDel()}
            fullWidth
          >
            Confirm
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Validate ticket</DialogTitle>
        <MDBox pt={2} py={-10} pb={3} px={3}>
          <MDTypography variant='button' color='black'>
            Do you want to validate this ticket?
          </MDTypography>
        </MDBox>
        <DialogActions>
          <MDButton
            variant='text'
            color='error'
            onClick={() => handleClose()}
            fullWidth
          >
            Cancel
          </MDButton>
          <MDButton
            variant='gradient'
            color='green'
            circular
            onClick={() => onsubmit()}
            fullWidth
          >
            Confirm
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog open={openUse} onClose={handleClose}>
      <DialogTitle>Use ticket</DialogTitle>
        <MDBox pt={2} py={-10} pb={3} px={3}>
          <MDTypography variant='button' color='black'>
            Do you want to mark this ticket as used?
          </MDTypography>
        </MDBox>
        <DialogActions>
          <MDButton
            variant='text'
            color='error'
            onClick={() => handleClose()}
            fullWidth
          >
            Cancel
          </MDButton>
          <MDButton
            variant='gradient'
            color='green'
            circular
            onClick={() => onsubmitUse()}
            fullWidth
          >
            Confirm
          </MDButton>
        </DialogActions>
      </Dialog>
      <EXtractTickets open={openExtractTicket} setOpen={setOpenExtractTicket} />
      <FilterTickets open={openFilterModel} setOpen={setOpenFilterModel} setFromDate={setFromDate} setToDate={setToDate} fromDate={sDate} toDate={eDate} setFilterByDay={setFilterByDay} setActiveDay={setActiveDay}/>
    </DashboardLayout>
  )
}

export default Tables
