import 'react-toastify/dist/ReactToastify.css'

import { useEffect, useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined'
import Card from '@mui/material/Card'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import DefaultProjectCard from 'examples/Cards/ProjectCards/DefaultProjectCard'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import ProfileCard from 'examples/Cards/InfoCards/ProfileInfoCard/profileEdited'
import ProfileInfoCard from 'examples/Cards/InfoCards/ProfileInfoCard'
import Select from 'react-select'
import Tooltip from '@mui/material/Tooltip'
import axios from 'axios'
import makeAnimated from 'react-select/animated'
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ProgressBar from './component/ProgressBar';
import { toast } from 'react-toastify'
import { app } from  '../../firebaseConfig';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
// Data
// Material Dashboard 2 React example components
// @mui material components
toast.configure()
const style = {
  width: 500,
}
const storage = getStorage(app);
const metadata = {
  contentType: 'image/jpeg'
};
const animatedComponents = makeAnimated()
const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};
function Gyms() {
  const [errors, setErrors] = useState('')
  const [errors1, setErrors1] = useState('')
  const [name, setName] = useState('')
  const [mobile, setMobile] = useState('')
  const [email, setEmail] = useState('')
  const [address, setAddress] = useState('')
  const [image, setImage] = useState('')
  const [imgAdd, setImgAdd] = useState()
  const [desc, setDesc] = useState('')
  const [loc, setLoc] = useState('')
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [activities, setActivities] = useState([])
  const [openEdit1, setOpenEdit1] = useState(false)
  const [openEdit2, setOpenEdit2] = useState(false)
  const [openDel, setOpenDel] = useState(false)
  const [activeBar, setActiveBar] = useState(false)
  const [openAdd, setOpenAdd] = useState(false)
  const [openLoc, setOpenLoc] = useState(false)
  const [Gym, setGym] = useState({})
  const [completed, setCompleted] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([])
  const notifySuccess = () => {
    toast.success('Successfull!', { autoClose: 3000 })
  }
  const notifyFailure = (msg) => {
    toast.error(msg, { autoClose: 3000 })
  }
  const style = {
    bgcolor: 'background.paper',
  }
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: state.isSelected ? 'red' : '#1e3a4d',
      borderRadius: 32,
      fontSize: 24,
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: 500,
    }),
  }
  const styles = {
    width: 600,
  }

  const handleClose = () => {
    setOpenEdit1(false)
    setOpenEdit2(false)
    setOpenDel(false)
    setOpenAdd(false)
    setOpenLoc(false)
    setErrors1('')
    setName('')
    setEmail('')
    setMobile('')
    setAddress('')
    setLoc('')
    setLatitude('')
    setLongitude('')
    setDesc('')
  }
  const handleEdit1 = () => {
    setErrors('')
    setOpenEdit1(true)
    setName(Gym.name)
    setEmail(Gym.email)
    setMobile(Gym.phone)
    setAddress(Gym.address)

    setDesc(Gym.about.trim())
  }
  const handleEdit2 = () => {
    setErrors('')
    setOpenEdit2(true)
    setName(Gym.name)
    setEmail(Gym.email)
    setMobile(Gym.phone)
    setAddress(Gym.address)
    setDesc(Gym.about.trim())
    console.log('email')
  }
  const handleImgDelete = (im) => {
    setErrors('')
    setOpenDel(true)
    setImage(im)
    setName(Gym.name)
    setEmail(Gym.email)
    setMobile(Gym.phone)
    setAddress(Gym.address)
    setDesc(Gym.about.trim())
  }
  const handleEditLoc = () => {
    setErrors('')
    setOpenLoc(true)
    setName(Gym.name)
    setEmail(Gym.email)
    setMobile(Gym.phone)
    setLatitude('')
    setLongitude('')
    setAddress(Gym.address)
    setDesc(Gym.about.trim())
  }

  const handleAddImg = () => {
    setErrors('')
    setOpenAdd(true)
    setName(Gym.name)
    setEmail(Gym.email)
    setMobile(Gym.phone)
    setAddress(Gym.address)
    setDesc(Gym.about.trim())
  }
  const getGym = async () => {
    const id = JSON.parse(localStorage.getItem('user')).gymId
    console.log(id)
    axios
      .get(`${process.env.REACT_APP_API_DEV}/gyms/${id}`)
      .then((res) => {
        const g = res.data
        setGym((prevState) => {
          return g
        })

        const ifameData = document.getElementById('iframeId')
        const lat = g.location?.lat
        const lon = g.location?.lng
        ifameData.src = `https://maps.google.com/maps?q=${lat},${lon}&hl=es;&output=embed`
      })
    //dispatch(GetGym(id))
  }
  const getActiv = async () => {
    let a
    await axios
      .get(`${process.env.REACT_APP_API_DEV}/activities`)
      .then((res) => {
        a = res.data
      })
    setActivities((prevState) => {
      return a
    })
  }
  const setData = async () => {
    const id = JSON.parse(localStorage.getItem('user')).gymId
    console.log('entery of setData: ', Gym.location)
    await axios
      .put(
        `${process.env.REACT_APP_API_DEV}/gyms/${id}`,
        {
          name: name,
          about: desc,
          activities: Gym.activities,
          address: address,
          location: Gym.location,
          images: Gym.images,
          coaches: Gym.coaches,
          imageURL: Gym.imageURL,
          phone: mobile,
        },
        {
          headers: {
            Authorization: `${localStorage.getItem('firebaseToken')}`,
          },
        }
      )
      .catch(function (error) {
        if (error.response) {
          console.log('response')
          notifyFailure('Please retry again.')
        } else if (error.request) {
          console.log('request')
          notifyFailure('Please retry again.')
          handleClose()
        } else {
          console.log('autre')
          notifyFailure('Please retry again.')
          handleClose()
        }
        console.log(error.config)
      })
      .then((res) => res.data)
      .then(() => handleClose())
      .then(notifySuccess)
    getGym()
  }
  const onsubmitDel = (im) => {
    if (Gym.images.length > 0) {
      const i = Gym.images.indexOf(im)
      if (i < Gym.images.length - 1) {
        console.log(Gym.images.splice(i, 1))
        console.log(i)
        console.log('first if')
        console.log(Gym.images)
        setData()
        handleClose()
      } else if (i === -1) {
        console.log('second if')
        notifyFailure('none')
        handleClose()
      } else {
        console.log(i)
        Gym.images.pop()
        console.log('third if')
        console.log(Gym.images)
        setData()
        handleClose()
      }
      console.log('******handle del******', Gym.images)
    } else {
      console.log('none')
    }
  }
  const data =
    activities &&
    activities.map((act) => ({
      label: act.name,
      value: act._id,
    }))
    const onsubmitAdd = () => {
      const id = JSON.parse(localStorage.getItem('user')).gymId
      if (!imgAdd) {
        setErrors('Please upload the image.')
      } else{
        setErrors('')
        const storageRef = ref(storage, id +'/gymPictures/' + imgAdd.name);
        const uploadTask = uploadBytesResumable(storageRef, imgAdd, metadata);
        
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on('state_changed',
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            setActiveBar(true)
            setCompleted(progress)
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused');
                break;
              case 'running':
                console.log('Upload is running');
                break;
            }
          }, 
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
              case 'storage/canceled':
                // User canceled the upload
                break;
        
              // ...
        
              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          }, 
          () => {
            // Upload completed successfully, now we can get the download URL
            getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {
               setCompleted(100)
              //  console.log("****",downloadURL)
                Gym.images.push(downloadURL)
                setActiveBar(false)
                setData()
               setCompleted(0)
               
            });
          }
        );
        
      }
    }
  function onlyNumbers(str) {
    return /^[0-9]+$/.test(str)
  }
  const onsubmit2 = () => {
    if (!desc) {
      setErrors('Please fill all the information.')
    } else {
      setData()
      getGym()
    }
  }
  const onsubmit1 = () => {
    if (mobile.length > 10 || !onlyNumbers(mobile)) {
      setErrors('Please enter a valid phone number.')
    } else if (!name || !mobile || !address || selectedOptions.length === 0) {
      setErrors('Please fill all the information.')
    } else {
      setErrors('')
      Gym.activities.splice(0, Gym.activities.length)
      for (let index = 0; index < selectedOptions.length; index++) {
        const element = selectedOptions[index]
        Gym.activities.push(element.value)
      }
      setData()
      getGym()
    }
  }

  const onsubmitLoc = () => {
    if (!latitude || !longitude) {
      setErrors1('')
      setErrors('Please enter the new coordinates.')
    } else {
      setErrors('')
      var l1 = latitude.replace('-', '')
      var l2 = longitude.replace('-', '')
      if (
        (onlyNumbers(l1.replace(',', '')) ||
          onlyNumbers(l1.replace('.', ''))) &&
        (onlyNumbers(l2.replace(',', '')) || onlyNumbers(l2.replace('.', '')))
      ) {
        setErrors1('')
        var lat = parseFloat(latitude.replace(',', '.'))
        var lon = parseFloat(longitude.replace(',', '.'))
        if (lat <= 90 && lat >= -90 && lon >= -180 && lon <= 180) {
          setErrors1('')
          console.log(lat, lon)
          Gym.location.lat = lat
          Gym.location.lng = lon
          setData()
          getGym()
        } else {
          setErrors('The longitude must be between 180 and -180.')
          setErrors1('The latitude must be between 90 and -90.')
        }
      } else {
        setErrors1('')
        console.log('Please enter valid coordinates')
      }
    }
  }

  useEffect(() => {
    if (localStorage.getItem("Token")) {
      const decodedJwt = parseJwt(localStorage.getItem("Token"));
      console.log(Date.now() )
      if (decodedJwt.exp * 1000 < Date.now()) {

      localStorage.removeItem('Token')
      }
    }
    const loggedInAdmin = localStorage.getItem("Token")
    if (!loggedInAdmin) {
      window.location.replace('/') 
    }
    getGym()
    getActiv()
  }, [])


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant='gradient'
                bgColor='green'
                borderRadius='lg'
                coloredShadow='info'
              >
                <MDTypography variant='h4' textAlign='center' color='white'>
                  My gym
                </MDTypography>
              </MDBox>
              <MDBox p={2} px={3}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6} xl={5.5}>
                    <ProfileCard
                      title='General information'
                      description=''
                      info={{
                        Name: `${Gym.name}`,
                        Mobile: `${Gym.phone}`,
                        Email: `${Gym.email}`,
                        Address: `${Gym.address}`,
                        activities: `${
                          Gym.activities &&
                          Gym.activities.map((activity, _) => activity.name)
                        }`,
                      }}
                      social={[]}
                      action={{ route: handleEdit1, tooltip: 'Edit gym' }}
                      shadow={false}
                    />
                  </Grid>
                  <Grid item xs={15} md={6} xl={5} sx={{ display: 'flex' }}>
                    <Divider orientation='vertical' sx={{ ml: -2, mr: 1 }} />
                    <ProfileInfoCard
                      title='About us'
                      description={Gym.about}
                      info={{}}
                      social={[]}
                      action={{
                        route: handleEdit2,
                        tooltip: 'Edit description',
                      }}
                      shadow={false}
                    />
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox
                display='flex'
                alignItems='center'
                lineHeight={1.25}
                pt={2}
                px={5}
              >
                <MDTypography variant='h3' fontWeight='medium'>
                  Photos
                </MDTypography>
                <IconButton
                  size='medium'
                  onClick={handleAddImg}
                  aria-label='add'
                  color='darkgreen'
                >
                  <Tooltip title='Add image' placement='bottom'>
                    <AddPhotoAlternateOutlinedIcon />
                  </Tooltip>
                </IconButton>
              </MDBox>
              <MDBox p={2} px={5}>
                <Grid container spacing={6}>
                  {Gym.images &&
                    Gym.images.map((image, i) => (
                      <Grid
                        item
                        xs={15}
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                        pt={2}
                        px={2}
                        md={6}
                        xl={3}
                      >
                        <DefaultProjectCard
                          image={image}
                          label=''
                          title=''
                          description=''
                          action={{ route: () => handleImgDelete(image) }}
                          authors={[]}
                        />
                        <MDTypography variant='h5' color='white'>
                          <Tooltip title='' placement='bottom'>
                            <Icon>clear</Icon>
                          </Tooltip>
                        </MDTypography>
                      </Grid>
                    ))}
                </Grid>
              </MDBox>
              <MDBox
                pt={2}
                px={5}
                lineHeight={1.25}
                display='flex'
                alignItems='center'
              >
                <MDTypography variant='h3' fontWeight='medium'>
                  Localisation
                </MDTypography>
                <IconButton
                  size='medium'
                  onClick={handleEditLoc}
                  aria-label='edit'
                  color='darkgreen'
                >
                  <Tooltip title='Edit localisation' placement='bottom'>
                    <ModeEditIcon />
                  </Tooltip>
                </IconButton>
              </MDBox>
              <MDBox p={2} px={5}>
                <Grid container spacing={6}>
                  <Grid item xs={15} md={6} xl={12}>
                    <iframe id='iframeId' height='500px' width='100%'></iframe>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Dialog open={openEdit1} onClose={handleClose}>
        <DialogTitle>Edit gym</DialogTitle>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component='form' role='form'>
            <MDBox mb={2}>
              <MDInput
                circular
                type='text'
                label='Name'
                value={name}
                fullWidth
                onChange={(e) => setName(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                circular
                value={mobile}
                type='text'
                label='Phone number'
                onChange={(e) => setMobile(e.target.value)}
                fullWidth
              />
            </MDBox>

            <MDBox mb={2}>
              <MDInput
                circular
                value={address}
                type='text'
                label='Address'
                onChange={(e) => setAddress(e.target.value)}
                fullWidth
                multiline
              />
            </MDBox>
            <MDBox mb={2}>
              <Select
                isMulti
                closeMenuOnSelect={false}
                components={animatedComponents}
                styles={customStyles}
                autoFocus
                placeholder='select your activities'
                options={data}
                onChange={(item) => setSelectedOptions(item)}
              />
            </MDBox>
            <MDBox mt={3} mb={1} textAlign='center'>
              <MDTypography variant='button' color='error'>
                {errors}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        <DialogActions>
          <MDButton
            variant='text'
            color='error'
            onClick={handleClose}
            fullWidth
          >
            Cancel
          </MDButton>
          <MDButton
            variant='gradient'
            color='green'
            circular
            onClick={() => onsubmit1()}
            fullWidth
          >
            Confirm
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog open={openEdit2} onClose={handleClose}>
        <DialogTitle>Edit description</DialogTitle>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component='form' role='form'>
            <MDBox mb={2}>
              <MDInput
                circular
                value={desc}
                type='text'
                sx={{ ...styles }}
                label='Description'
                onChange={(e) => setDesc(e.target.value)}
                fullWidth
                multiline
              />
            </MDBox>
            <MDBox mt={3} mb={1} textAlign='center'>
              <MDTypography variant='button' color='error'>
                {errors}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        <DialogActions>
          <MDButton
            variant='text'
            color='error'
            onClick={handleClose}
            fullWidth
          >
            Cancel
          </MDButton>
          <MDButton
            variant='gradient'
            color='green'
            circular
            onClick={() => onsubmit2()}
            fullWidth
          >
            Confirm
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog open={openDel} onClose={handleClose}>
        <DialogTitle>Delete image</DialogTitle>
        <MDBox pt={2} py={-10} pb={3} px={3}>
          <MDTypography variant='button' color='black'>
            Do you want to delete this image?
          </MDTypography>
        </MDBox>
        <DialogActions>
          <MDButton
            variant='text'
            color='error'
            onClick={() => handleClose()}
            fullWidth
          >
            Cancel
          </MDButton>
          <MDButton
            variant='gradient'
            color='green'
            circular
            onClick={() => onsubmitDel(image)}
            fullWidth
          >
            Confirm
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog open={openAdd} onClose={handleClose}>
        <DialogTitle>Add photo</DialogTitle>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component='form' role='form'>
            <MDBox mb={2}>
            <Tooltip title="Upload image" placement='bottom'>
         
                <IconButton color="secondary" aria-label="upload picture" component="label">
                Upload 
            <input hidden accept="image/*" onChange={(e) => setImgAdd(e.target.files[0])} type="file"  />
            <PhotoCamera />
            </IconButton>
            </Tooltip>
            </MDBox>

            <MDBox mt={3} mb={1} textAlign='center'>
              <MDTypography variant='button' textTransform="none" color='error'>
              {activeBar &&  <ProgressBar bgcolor={"#6a1b9a"} completed={completed} />}
                {errors}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        <DialogActions>
        <MDButton
            variant='text'
            color='error'
            onClick={handleClose}
            fullWidth
          >
            Cancel
          </MDButton>
          <MDButton
            variant='gradient'
            color='green'
            circular
            onClick={() => onsubmitAdd()}
            fullWidth
          >
            Confirm
          </MDButton>

        </DialogActions>
      </Dialog>
      <Dialog open={openLoc} onClose={handleClose}>
        <DialogTitle>Edit localisation</DialogTitle>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component='form' role='form'>
            <MDBox mb={2}>
              <MDInput
                circular
                type='text'
                label='Latitude'
                fullWidth
                onChange={(e) => setLatitude(e.target.value)}
                multiline
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                circular
                type='text'
                label='Longitude'
                fullWidth
                onChange={(e) => setLongitude(e.target.value)}
                multiline
              />
            </MDBox>
            <MDBox mt={3} mb={1} textAlign='left'>
              <MDTypography variant='button' color='error'>
                {errors}
              </MDTypography>
            </MDBox>
            <MDBox textAlign='left'>
              <MDTypography variant='button' color='error'>
                {errors1}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        <DialogActions>
          <MDButton
            variant='text'
            color='error'
            onClick={handleClose}
            fullWidth
          >
            Cancel
          </MDButton>
          <MDButton
            variant='gradient'
            color='green'
            circular
            onClick={() => onsubmitLoc()}
            fullWidth
          >
            Confirm
          </MDButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  )
}

export default Gyms
