import borders from 'assets/theme/base/borders'
import boxShadows from 'assets/theme/base/boxShadows'
// Material Dashboard 2 React base styles
import colors from 'assets/theme/base/colors'

const { white } = colors
const { md } = boxShadows
const { borderRadius } = borders

const tableContainer = {
  styleOverrides: {
    root: {
      backgroundColor: white.main,
      boxShadow: md,
      borderRadius: borderRadius.xl,
    },
  },
}

export default tableContainer
