/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from 'react'

import Card from '@mui/material/Card'
// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import DataTable from 'examples/Tables/DataTable'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import DialogTitle from '@mui/material/DialogTitle'
import EXtractTickets from './modals/extractTickets'
import Footer from 'examples/Footer'
// @mui material components
import Grid from '@mui/material/Grid'
// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import Navbar from "examples/Navbars/TableNavbar";
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MaterialTable from 'material-table'
import moment from 'moment'
import ConfirmUsedTicket from './modals/mardAsUsed'
import ConfirmNoShow from './modals/confirmNoShow'
// Data
import authorsTableData from 'layouts/presences/data/authorsTableData'
import axios from 'axios'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import HelpIcon from '@mui/icons-material/Help';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { toast } from 'react-toastify'

toast.configure()
// import projectsTableData from "layouts/tables/data/projectsTableData";
const pf = process.env.API_DEV
const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};
function Presences() {
  const [expandedRows, setExpandedRows] = useState("");
  const [disable, setDisable] = useState(false)
  const [openDel, setOpenDel] = useState(false)
  const [open, setOpen] = useState(false)
  const [openUse, setOpenUse] = useState(false)
  const [openNoShow, setOpenNoShow] = useState(false)
  const [reason, setReason] = useState(false)
  const [selectedDayFilter, setSelectedDayFilter] = useState(moment(new Date()).format('YYYY-MM-DD'))
  const [errors, setErrors] = useState('')
  const [TickersData, setTickersData] = useState([])
  const [TicketsGroupped, setTicketsGroupped] = useState([])
  const [openExtractTicket, setOpenExtractTicket] = useState(false)
  const [data, setData] = useState({})
  const [activeDay, setActiveDay] = useState("Aujourd'hui")
  const [Gym, setGym] = useState({})
  const startDate = new Date()
  const endDate = new Date()
  startDate.setDate(startDate.getDate() - 3)
  startDate.setHours(0, 0, 0, 0)
  endDate.setHours(23, 59, 59, 999)
  const smalldayMap =([
    ["Sun"],
    ["Mon"],
    ["Tue"],
    ["Wed"],
    ["Thu"],
    ["Fri"],
    ["Sat"]
  ]);
  const [days, setDays] = useState([]);
  let columns = (authorsTableData(TickersData).columns)
  let rows = (authorsTableData(TickersData).rows)


  function getsmallFormatedDate(date) {
    if (date instanceof Date === false) {
      date = new Date(date);
    }
    const currentDate = new Date()
    if (currentDate.getDay() == date.getDay()) {
      return "Aujourd'hui"
    }else if (currentDate.getDay() - 1 == date.getDay()) {
      return "Hier"
    }else{
      return  `${smalldayMap[date.getDay()]} ${date.getDate()}`
    }
    
  }
  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() - days);
    return {
      name: getsmallFormatedDate(result),
      value: result
    };
  }
  const rowsF= ()=>{
    
    let rowsToreturn = rows.filter(row => (row.status.includes("available") || row.status.includes("used")) && row.availability.includes("accepted") );
    if (selectedDayFilter) {

     return rowsToreturn.filter(row => row.classDate.includes(selectedDayFilter) );
    }
    return rowsToreturn
  }
  const notifySuccess = () => {
    toast.success('Successfull', { autoClose: 3000 })
  }
  const notifyFailure = (msg) => {
    toast.error(msg, { autoClose: 3000 })
  }
  const handleClickDay = (date) =>{
    setActiveDay(date.name)
    if (date.value) {
      setSelectedDayFilter(moment(new Date(date.value)).format('YYYY-MM-DD'))
    }else{
      setSelectedDayFilter(null)
    }
    
  }
  const handleClose = () => {
    setOpenDel(false)
    setOpenUse(false)
    setOpen(false)
    setErrors('')
  }
  const getGym = async () => {
    const id = JSON.parse(localStorage.getItem('user')).gymId

    axios
      .get(`${process.env.REACT_APP_API_DEV}/gyms/${id}`)
      .then((res) => {
        const g = res.data
        setGym((prevState) => {
          return g
        })
      })
    //dispatch(GetGym(id))
  }


  const onsubmitUse = async () =>{
    await axios
    .patch(`${process.env.REACT_APP_API_DEV}/tickets/useTicket`, {
      id: data.id,
      userId: data.userId,
    },
    {
      headers: {
        Authorization: `${localStorage.getItem('firebaseToken')}`,
      },
    })
    .catch(function (error) {
      if (error.response) {
        console.log("response error")
        notifyFailure(error.response.data.message)
        setOpenDel(false)
      } else if (error.request) {
        notifyFailure('Please retry again')
        setOpenDel(false)
      } else {
        notifyFailure('Please retry again')
        setOpenDel(false)
      }
      console.log(error.config)
    })
    .then(setOpenUse(false))
    .then((res) => {
      if (res.data) {
       
        notifySuccess()
      }})
    .then(getData)
    .setErrors('')
  }


  const handleUseTicket = async (rowData) =>{
    setOpenUse(true)
    setData(rowData)
  }
  const getData = async () => {
    setOpenDel(false)
    setOpen(false)
    setData(
      axios
        .get(`${process.env.REACT_APP_API_DEV}/tickets/all-by-status-by-gym`, {
          headers: {
            Authorization: `${localStorage.getItem('firebaseToken')}`,
          },
        })
        .then((res) => {
          const Tickets = res.data

          setTickersData(Tickets)
        })
    )
    columns = (authorsTableData(TickersData).columns)
    rows = (authorsTableData(TickersData).rows)
  }
  const getTicketsGroupedByClass = async () => {
    setOpenDel(false)
    setOpen(false)
    axios
    .get(`${process.env.REACT_APP_API_DEV}/tickets/tickets-grouped-by-class?fromDate=${startDate}&toDate=${endDate}`, {
      headers: {
        Authorization: `${localStorage.getItem('firebaseToken')}`,
      },
    })
    .then((res) => {
      const Tickets = res.data
      setTicketsGroupped(Tickets)
   
    })
  }
  const disableUseButton = (date)=>{


  let modifiedDate = new Date(date);
  modifiedDate.setTime(modifiedDate.getTime() - 60 * 60 * 1000); 

  if (modifiedDate > new Date()) {
    return true;
  }
    return false
  }

  const styleTitle = (element) =>{

    const currentDate = new Date()
    const lenOfRemainingTickets = element?.tickets?.filter(row => row.status.includes("available") && row.availabilityStatus.includes("accepted") ).length
    let modifiedDateByThirtyMin = new Date(element.classDate);
    modifiedDateByThirtyMin.setTime(modifiedDateByThirtyMin.getTime() - 30 * 60 * 1000)
    if (containsLibre(element?.tickets[0]?.class?.title)) {
      if (lenOfRemainingTickets > 0) {
        if (currentDate >= new Date(element.classEnd)) {
          return {'icon':'warning', 'remaining':lenOfRemainingTickets, 'color': 'red', "modifiedDate": modifiedDateByThirtyMin}
        }else{
          return {'icon':'warning', 'remaining':lenOfRemainingTickets, 'color': 'blue', "modifiedDate": modifiedDateByThirtyMin}
        }
       
      }else{
        return {'icon':'done', 'remaining':0, 'color': 'green', "modifiedDate": modifiedDateByThirtyMin}
      }
    }else if (currentDate < modifiedDateByThirtyMin) {
      return  {'icon': 'disabled', 'remaining':lenOfRemainingTickets, 'color': 'black', "modifiedDate": modifiedDateByThirtyMin}
    }
    else if(currentDate >= modifiedDateByThirtyMin && currentDate < new Date(element.classEnd)){
     
      if (lenOfRemainingTickets > 0) {
        return {'icon':'warning', 'remaining':lenOfRemainingTickets, 'color': 'blue', "modifiedDate": modifiedDateByThirtyMin}
      }else{
        return {'icon':'done', 'remaining':0, 'color': 'green', "modifiedDate": modifiedDateByThirtyMin}
      }
    }else if (currentDate >= new Date(element.classEnd)) {

      if (lenOfRemainingTickets > 0) {
        return {'icon':'warning', 'remaining':lenOfRemainingTickets, 'color': 'red', "modifiedDate": modifiedDateByThirtyMin}
      }else{
        return {'icon':'done', 'remaining':0, 'color': 'green', "modifiedDate": modifiedDateByThirtyMin}
      }
    } else{
      return  {'icon': 'none', 'remaining':lenOfRemainingTickets, 'color': 'black', "modifiedDate": modifiedDateByThirtyMin}
    }
  }
  const containsLibre = (name)=>{
    console.log("name ::", name)
    if (name?.toLowerCase().includes("libre")) {
      return true
    }
    return false
  }
  useEffect(() => {
    if (localStorage.getItem("Token")) {
      const decodedJwt = parseJwt(localStorage.getItem("Token"));

      if (decodedJwt.exp * 1000 < Date.now()) {

      localStorage.removeItem('Token')
      }
    }
    const loggedInAdmin = localStorage.getItem("Token")
    if (!loggedInAdmin) {
      window.location.replace('/') 
    }
    axios.defaults.headers.common['Authorization'] = `${localStorage.getItem(
      'firebaseToken'
    )}`
    //getData()
    getTicketsGroupedByClass()
    getGym()
rowsF()

    
  }, [])
  useEffect(() => {

    if (days.length <= 4) {
  
     for (let index = 0; index < 4; index++) {
      
      let date = new Date();
      days.push(addDays(date, index));
  
     }
     setDays(days.reverse())
    }
  }, [days])

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>

              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant='gradient'
                bgColor='green'
                borderRadius='lg'
                coloredShadow='info'
              >
                <MDTypography variant='h4' textAlign='center' color='white'>
                  Marquer les présences
                </MDTypography>
              </MDBox>
              <Navbar days={days} activeDay={activeDay} handleClick={handleClickDay} /> 
              <MDBox pt={3}>
                {/* <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                  canSearch
                /> */}
                  {TicketsGroupped.filter(row => row.classDate.includes(selectedDayFilter) ).length > 0 &&
                    TicketsGroupped.filter(row => row.classDate.includes(selectedDayFilter)).sort((a, b) => new Date(a.classDate) - new Date(b.classDate)).map((t) => (
                      <MDBox>
                              <MDTypography variant='h5' textTransform="none" marginLeft={5} marginTop={2} >
                                  {t?.classTitle} à {moment(new Date(t?.classDate).getTime()).format('HH:mm')}

                                  <IconButton
                                size='medium'
                                onClick={() => {
                                  if (expandedRows.includes(t.classId)) {
                                    setExpandedRows("");
                                  } else{
                                    setExpandedRows(t.classId);
                                  }
                                }}
                                aria-label='expand'
                              >
                                {!expandedRows.includes(t.classId) &&
                                 <Tooltip title='Expand more' placement='bottom'>
                                 <ExpandMoreIcon />
                               </Tooltip> }

                               
                                {expandedRows.includes(t.classId) && 
                                 <Tooltip title='Collapse' placement='bottom'>
                                 <ExpandLessIcon />
                               </Tooltip>
                                }
                               
                              </IconButton>

                                  {styleTitle(t).icon.includes("done")  &&
                                  <span style={{color:"#26D98C", fontSize:"15px", marginLeft:"25px"}}>
                                   <CheckCircleIcon style={{marginTop: -2}} /> Merci, toutes les présences sont marquées
                                    </span>
                                  }
                                  {styleTitle(t).icon.includes("disabled") && 
                                  <span style={{color:styleTitle(t).color, fontSize:"15px", marginLeft:"25px"}}>
                                    <RemoveCircleOutlineIcon style={{marginTop: -3, marginRight: 2}}/>

                                    Vous pourrez marquer les présences à partir de {moment(new Date(styleTitle(t).modifiedDate).getTime()).format('HH:mm')}
                                    </span>
                                  }
                                  {styleTitle(t).color.includes("red") && 
                                  
                                  <span style={{color:"#f44336", fontSize:"15px", marginLeft:"25px", fontWeight:"bold"}}>
                                
                                    <ErrorIcon style={{marginTop: -2, marginRight: 2}}  />
                                    Il vous reste {styleTitle(t).remaining} présences à marquer. Nous vous prions de marquer les présences/absences.
                                    </span>
                                  }
                                  {styleTitle(t).color.includes("blue") && 
                                  <span style={{color:"#00bbd4", fontSize:"15px", marginLeft:"25px", fontWeight:"bold"}}>
                                    <HelpIcon style={{marginTop: -3, marginRight: 2}}  />
                                    Il vous reste {styleTitle(t).remaining} présences à marquer.
                                    </span>
                                  }
                                 
                              </MDTypography>
                              {expandedRows.includes(t.classId) && (
                                  <MaterialTable
                                  style={{ marginTop: -4, boxShadow: 'none' }}
                                  columns={columns}
                                  data={authorsTableData(t.tickets).rows}
                                  title=' '
                                  actions={[
  
                                     (rowData) => ({
                                      icon: 'playlist_add_check',
                                      tooltip: 'Si la personne s’est présentée au cours, veuillez marquer sa présence.',
                                      iconProps: { style: { color: "#26D98C"} },
                                      hidden: rowData.status == 'noShow' || rowData.status == 'used' || Gym.canMarkAsUsed == false || Gym.canMarkAsUsed == null || styleTitle(t).icon.includes("disabled"),
                                      onClick: async (event, rowData) => {
                                        handleUseTicket(rowData)
                                      },
                                    }),
                                    (rowData) => ({
                                      icon: 'person_remove_icon',
                                      tooltip: "Si la personne s’est absentée au cours, veuillez marquer son absence.",
                                      iconProps: { style: { color: "red"} },
                                      hidden: rowData.status == 'noShow' || rowData.status == 'used' || Gym.canMarkAsUsed == false || Gym.canMarkAsUsed == null || styleTitle(t).icon.includes("disabled"),
                                      onClick: async (event, rowData) => {
                                        setData(rowData)
                                        setOpenNoShow(true)
                                      },
                                    }),
                                  ]}
                                  options={{
                                    headerStyle: {
                                      backgroundColor: '#EFF4F2',
                                      color: 'black',
                                      whiteSpace: 'nowrap',
                                  //   marginTop: -5
                                    },
                                    filtering: true,
                                    search: false,
                                    sorting: false,
                                    tableLayout: 'auto',
                                    emptyRowsWhenPaging: false,
                                    pageSize: 10,
                                  //  actionsColumnIndex: -1,
                                    pageSizeOptions: [10],
                                    rowStyle: (rowData) => {
                                      if (rowData.tableData.id % 2)
                                        return {
                                          backgroundColor: '#FAFAFA',
                                        }
                                    },
                                  }}
                                />
                              )}
                      </MDBox>
              
                    ))}

              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>


      <ConfirmNoShow       open={openNoShow}    setOpen={setOpenNoShow}     ticket={data} getAllData={getTicketsGroupedByClass}/>
      <ConfirmUsedTicket   open={openUse}    setOpen={setOpenUse}  ticket={data} getAllData={getTicketsGroupedByClass}/>
     
    </DashboardLayout>
  )
}

export default Presences
